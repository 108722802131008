import PATHS from '../routes/paths';

export default {
  GLOBAL: {
    SITENAME: 'EPG Schedule',
    FOOTER: {
      COPYRIGHT_TEXT: '© {year} NBCU. All rights reserved.',
      NBC: 'NBCUniversal Networks International Networks / 05325358',
      UTIL_MENU_TITLE: 'Useful Links',
      ADMIN_MENU_TITLE: 'A Division of NBCUniversal',
      CONTACT_TEXT: null,
      COMMISSION_DETAILS: null,
      CNBC_COPY: null,
      SOCIAL: [],
    },
    NAVIGATION: {
      MAIN: {
        HOME: 'Home',
      },
      SITE_MENU: [
        { title: 'Subscribe', href: PATHS.subscribe },
        { title: 'Terms and Conditions', href: PATHS.terms },
      ],
      UTIL_MENU: [
        {
          title: 'Privacy Policy',
          href: 'https://www.nbcuniversal.com/privacy/',
        },
      ],
      ADMIN_MENU: [
        { title: 'E! Online', href: 'http://uk.eonline.com/' },
        { title: 'Movies 24', href: 'http://www.tvmovies24.com/' },
        {
          title: 'Universal Channel',
          href: 'http://www.universalchannel.co.uk/',
        },
      ],
    },
  },
  UI: {
    SUBSCRIBE_BTN: 'Subscribe',
  },
  PAGES: {
    HOME: {
      TITLE: 'EPG',
    },
    NOT_FOUND: {
      TITLE: 'Oh No! Page not Found',
      MESSAGE: 'Page Not Found',
    },
    SUBSCRIBE: {
      TITLE: 'Subscribe',
      NO_AFFIILIATES_MESSAGE:
        'There are no Partners registered for the selected Country.',
    },
    TERMS: {
      TITLE: 'Terms & Conditions',
    },
    COMPETITION_RULES: {
      TITLE: '',
    },
    LEGAL_NOTICE: {
      TITLE: '',
    },
    TERMS_OF_SALE: {
      TITLE: '',
    },
  },
  SCHEDULE: {
    TITLE: 'Schedule',
    MORNING: 'Morning',
    AFTERNOON: 'Afternoon',
    EVENING: 'Evening',
    ON_AIR: 'On Air',
    NO_CONTENT: 'No schedule content available',
    SEASON: 'Season',
    EPISODE: 'Episode',
    DOWNLOAD_BTN_TEXT: 'Download Schedule',
  },
  DATE: {
    DAYS: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    MONTHS: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  },
  COOKIE_BANNER: {
    UI: {
      POPUP_BACKGROUND: '#000000',
      POPUP_TEXT: '#ffffff',
      BUTTON_BACKGROUND: '#000000',
      BUTTON_TEXT: '#ffffff',
    },
    COPY: {
      MESSAGE:
        'This website uses cookies to ensure you get the best experience on our website.',
      DISMISS: 'Ok',
      LINK: 'Learn more',
      HREF: 'https://www.nbcuniversal.com/privacy/cookies',
    },
  },
  AFFILIATES: [
    {
      NAME: 'BT',
      LINK: 'http://www.productsandservices.bt.com/products/bt-tv/',
      IMAGENAME: 'bt.png',
      SUBTITLE: null,
    },
    {
      NAME: 'Sky',
      LINK: 'http://www.sky.com/',
      IMAGENAME: 'skylogo-2.png',
      SUBTITLE: null,
    },
    {
      NAME: 'Virgin Media',
      LINK: 'http://store.virginmedia.com/index.html?id=1',
      IMAGENAME: 'virgin.png',
      SUBTITLE: null,
    },
  ],
  TERMS: '<div class="copy__text"> <p> Example Terms"</div>',
  COMPETITION_RULES: '',
  TERMS_OF_SALE: '',
  LEGAL_NOTICE: '',
};
