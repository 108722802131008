import React, { useEffect, useState } from 'react';
import {
  Heading,
  Type,
  ContentPageLayout,
  ContentRowsLayout,
} from '@nbcuniversal-paint/nbcu-react-components';
import renderHTML from 'html-react-parser';
import Page from '../../components/Page';
import strings from '../../translations';

const { TITLE } = strings.PAGES.TERMS;
const { TERMS } = strings;

const Terms = () => {
  return (
    <Page title={TITLE}>
      <ContentPageLayout>
        <ContentRowsLayout>
          <div>
            <Heading tag="h1" className="h1">
              {TITLE}
            </Heading>
          </div>
          <Type align="left">{renderHTML(TERMS)}</Type>
        </ContentRowsLayout>
      </ContentPageLayout>
    </Page>
  );
};
export default Terms;
