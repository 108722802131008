import React, { useEffect } from 'react';
import {
  Heading,
  ContentPageLayout,
  ContentRowsLayout,
} from '@nbcuniversal-paint/nbcu-react-components';
import { useParams } from 'react-router-dom';
import Page from '../../components/Page';
import strings from '../../translations';
import styles from './Schedule.module.scss';
import ScheduleList from '../../containers/ScheduleList';
import BannerImage from '../../components/BannerImage';
import image from '../../assets/images/hero/Hero_Mosaic_16.9_Oct 23.png';
import logo1 from '../../assets/images/logos/UniversalPlus-xl-cropped.png';
import logo2 from '../../components/Elements/LogoMono/13thstreet/13_eme_rue.png';
import logo3 from '../../components/Elements/LogoMono/syfywire/syfy.png';

const { TITLE } = strings.PAGES.HOME;

const Schedule = () => {
  const params = useParams();
  const { id } = params;
  if (!id) {
    return null;
  }

  const theme = process.env.REACT_APP_THEME;
  const epgName = process.env.REACT_APP_EPGNAME;

  useEffect(() => {
    const script = document.createElement('script');

    script.src = process.env.REACT_APP_CDN_SRC || '';
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.setAttribute(
      'data-domain-script',
      process.env.REACT_APP_COOKIE_ID || '',
    );
    script.integrity = process.env.REACT_APP_COOKIE_INTEG || '';
    script.crossOrigin = 'anonymous';
    script.setAttribute('data-document-language', 'true');

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Page title={TITLE}>
      <ContentPageLayout spacing="none" flush>
        <ContentRowsLayout>
          {(theme === '13thstreet' || theme === 'syfywire') &&
            (epgName === '13emerue' || epgName === 'syfy') && (
              <div style={{ position: 'relative' }}>
                <BannerImage image={image} />
                <div
                  style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    height: '100%',
                    width: '500px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={logo1}
                      style={{ width: '220px' }}
                      alt="banner image"
                    />

                    {theme === '13thstreet' && (
                      <img
                        src={logo2}
                        style={{ width: '240px' }}
                        alt="banner image"
                      />
                    )}

                    {theme === 'syfywire' && (
                      <img
                        src={logo3}
                        style={{ width: '240px' }}
                        alt="banner image"
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

          <Heading tag="h1" className="sr-only">
            {TITLE}
          </Heading>

          <div className={styles.layout}>
            <ScheduleList currentDate={id} />
          </div>
        </ContentRowsLayout>
      </ContentPageLayout>
    </Page>
  );
};

export default Schedule;
