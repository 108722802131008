import React from 'react';
import cx from 'classnames';
import { Heading } from '@nbcuniversal-paint/nbcu-react-components';
import styles from './List.module.scss';
import ListItem from '../ListItem';
import string from '../../translations';
import { getIsoDate, dateAdd } from '../../tools/dateFuncs';
import { Entity } from '../../modules/schedule/schedule';

const { MORNING, AFTERNOON, EVENING } = string.SCHEDULE;

type Props = {
  items: Entity[];
};

const List = ({ items }: Props): React.ReactElement | null => {
  if (!items || items.length === 0) {
    return null;
  }
  const now = new Date().getTime();

  const ScheduleItemsMorning = items.map((data, index) => {
    const {
      uuid,
      time,
      duration,
      date,
      title,
      episode_title: episodeTitle,
      series_number: seriesNumber,
      episode_number: episodeNumber,
      synopsis,
    } = data;
    const { _: details } = synopsis || {};
    const t = time.split(':');
    if (Number(t[0]) > 12) return null;
    const dateTime = getIsoDate(date, '/', time);
    const startTime = new Date(dateTime).getTime();
    const endTime = dateAdd(dateTime, 'minute', duration).getTime();

    // Next item
    const nextItem = items[index + 1];
    const nextItemStartTime =
      nextItem &&
      new Date(getIsoDate(nextItem.date, '/', nextItem.time)).getTime();
    const end = nextItemStartTime ? now < nextItemStartTime : now < endTime;
    const nowOn = !!(now > startTime && end);
    return (
      <ListItem
        nowOn={nowOn}
        key={uuid}
        time={time}
        episodeTitle={episodeTitle}
        seriesNumber={seriesNumber}
        episodeNumber={episodeNumber}
        details={details}
        title={title}
      />
    );
  });

  const ScheduleItemsAfternoon = items.map((data, index) => {
    const {
      uuid,
      time,
      duration,
      date,
      title,
      episode_title: episodeTitle,
      series_number: seriesNumber,
      episode_number: episodeNumber,
      synopsis,
    } = data;
    const { _: details } = synopsis || {};
    const t = time.split(':');
    if (Number(t[0]) < 13 || Number(t[0]) > 18) return null;
    const dateTime = getIsoDate(date, '/', time);
    const startTime = new Date(dateTime).getTime();
    const endTime = dateAdd(dateTime, 'minute', duration).getTime();

    // Next item
    const nextItem = items[index + 1];
    const nextItemStartTime =
      nextItem &&
      new Date(getIsoDate(nextItem.date, '/', nextItem.time)).getTime();
    const end = nextItemStartTime ? now < nextItemStartTime : now < endTime;
    const nowOn = !!(now > startTime && end);
    return (
      <ListItem
        nowOn={nowOn}
        key={uuid}
        time={time}
        episodeTitle={episodeTitle}
        seriesNumber={seriesNumber}
        episodeNumber={episodeNumber}
        details={details}
        title={title}
      />
    );
  });

  const ScheduleItemsEvening = items.map((data, index) => {
    const {
      uuid,
      time,
      duration,
      date,
      title,
      episode_title: episodeTitle,
      series_number: seriesNumber,
      episode_number: episodeNumber,
      synopsis,
    } = data;
    const { _: details } = synopsis || {};
    const t = time.split(':');
    if (Number(t[0]) < 19) return null;

    const dateTime = getIsoDate(date, '/', time);
    const startTime = new Date(dateTime).getTime();
    const endTime = dateAdd(dateTime, 'minute', duration).getTime();

    // Next item
    const nextItem = items[index + 1];
    const nextItemStartTime =
      nextItem &&
      new Date(getIsoDate(nextItem.date, '/', nextItem.time)).getTime();
    const end = nextItemStartTime ? now < nextItemStartTime : now < endTime;
    const nowOn = !!(now > startTime && end);
    return (
      <ListItem
        nowOn={nowOn}
        key={uuid}
        time={time}
        episodeTitle={episodeTitle}
        seriesNumber={seriesNumber}
        episodeNumber={episodeNumber}
        details={details}
        title={title}
      />
    );
  });

  return (
    <div className={styles.layout}>
      <div>
        <Heading
          tag="h3"
          className={cx('h3', 'list-heading-morning', styles.heading)}
        >
          {MORNING}
        </Heading>
        <div>{ScheduleItemsMorning}</div>
      </div>
      <div>
        <Heading
          tag="h3"
          className={cx('h3', 'list-heading-afternoon', styles.heading)}
        >
          {AFTERNOON}
        </Heading>
        <div>{ScheduleItemsAfternoon}</div>
      </div>
      <div>
        <Heading
          tag="h3"
          className={cx('h3', 'list-heading-evening', styles.heading)}
        >
          {EVENING}
        </Heading>
        <div>{ScheduleItemsEvening}</div>
      </div>
    </div>
  );
};

export default List;
