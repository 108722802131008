import React from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  Heading,
  Container,
  Type,
  Button,
} from '@nbcuniversal-paint/nbcu-react-components';
import ComponentLoader from '../../components/ComponentLoader';
import selectScheduleItems from '../../modules/schedule/schedule.selectors';
import styles from './ScheduleList.module.scss';
import strings from '../../translations';
import List from '../../components/List';
import { formatDate, getIsoDate } from '../../tools/dateFuncs';
import ScheduleNav from '../../components/ScheduleNav';
import { AWS_ENDPOINT, EPG_REGION } from '../../constants';
import fetchScheduleData from '../../modules/schedule/schedule.actions';
import { State, Entity } from '../../modules/schedule/schedule';
import LogoMono from '../../components/Elements/LogoMono';

const { TITLE, NO_CONTENT, DOWNLOAD_BTN_TEXT } = strings.SCHEDULE;

type Props = {
  className?: string;
  error?: boolean;
  currentDate: string;
};

const ScheduleList = ({ className, error, currentDate }: Props) => {
  const [loading, setLoading] = React.useState(true);
  const [activeDate, setActiveDate] = React.useState<string>(
    String(currentDate),
  );
  const [activeURL, setActiveURL] = React.useState(
    `${AWS_ENDPOINT}/${EPG_REGION}/${currentDate}.json`,
  );
  const dispatch = useDispatch();
  const scheduleItems = useSelector((state: State) =>
    selectScheduleItems(state, currentDate),
  );

  const theme = process.env.REACT_APP_THEME;
  const epgName = process.env.REACT_APP_EPGNAME;

  React.useEffect(() => {
    /**
     * Fetch data.
     */
    function fetchData() {
      dispatch(fetchScheduleData(String(currentDate)));
      setLoading(false);
    }

    fetchData();
  }, []);

  const updateActiveDate = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    manualDate = null,
  ) => {
    const newDate =
      manualDate || event?.currentTarget?.dataset?.date || activeDate;
    dispatch(fetchScheduleData(String(newDate)));
    setActiveDate(newDate);
    setActiveURL(`${AWS_ENDPOINT}/${EPG_REGION}/${newDate}.json`);
  };

  /**
   * Sort the schedule items by date of each item.
   *
   * @param {Array} data
   */
  const sortSchedule = (data: Entity[]): Entity[] => {
    const formattedDate = getIsoDate(currentDate);
    const todaysDate = formatDate(formattedDate);
    return data.sort((a: Entity, b: Entity) => {
      const ad = new Date(`${todaysDate} ${a.time}`);
      const bd = new Date(`${todaysDate} ${b.time}`);
      return Number(ad) - Number(bd);
    });
  };

  if (error)
    return (
      <>
        <div className={cx(className, styles.layout)}>
          <div className={styles.wrapper}>
            <Heading tag="h1" className={cx('h1', styles.heading)}>
              {TITLE}
            </Heading>
            <ScheduleNav
              currentDate={currentDate}
              callBack={updateActiveDate}
            />
            <Container>
              <Type as="p">{NO_CONTENT}</Type>
            </Container>
          </div>
        </div>
      </>
    );

  if (!scheduleItems) return null;

  const sortedSchedule = sortSchedule(scheduleItems);
  return (
    <>
      <div className={cx(className, styles.layout)}>
        <div className={cx('schedule-list-wrapper', styles.wrapper)}>
          {epgName !== '13emerue' && epgName !== 'syfy' && (
            <Heading tag="h1" className={cx('h1', styles.heading)}>
              {TITLE}
            </Heading>
          )}

          {(epgName === '13emerue' || epgName === 'syfy') && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LogoMono width="120" />
              <p
                style={{
                  color: '#fff',
                  fontFamily:
                    epgName === 'syfy'
                      ? 'SYFY_Hero-Regular'
                      : 'NudistaScar-Bold, sans-serif',
                  fontSize: epgName === 'syfy' ? '72px' : '2.625rem',
                }}
              >
                {strings.SCHEDULE.TITLE}
              </p>
            </div>
          )}

          <ScheduleNav currentDate={currentDate} callBack={updateActiveDate} />
          {loading && (
            <div className={styles.loader}>
              <ComponentLoader />
            </div>
          )}
          {!loading && (
            <Container>
              <List items={sortedSchedule} />
            </Container>
          )}
        </div>
        <div>
          <Button
            className={styles.downloadBtn}
            tag="link"
            variants={['primary']}
            href={activeURL}
            text={DOWNLOAD_BTN_TEXT}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(ScheduleList);
