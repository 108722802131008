import PATHS from '../routes/paths';

export default {
  GLOBAL: {
    SITENAME: 'DREAMWORKS',
    FOOTER: {
      COPYRIGHT_TEXT:
        '© {year} NBC Universal Global Networks España S.L.U.  All rights reserved.',
      NBC: 'Torre Europa, Paseo de la Castellana 95, Planta 10, 28046 Madrid, SPAIN RCS: B-82227893 MADRID',
      UTIL_MENU_TITLE: 'handige links',
      ADMIN_MENU_TITLE: 'Een afdeling van NBCUniversal',
      CONTACT_TEXT:
        'Neem contact met ons op via e-mail : contact.dreamworksnl@nbcuni.com.',
      COMMISSION_DETAILS:
        'NBC Universal Global Networks España S.L.U. is wholly owned by Universal Studios International BV',
      CNBC_COPY:
        'is subject to the Spanish jurisdiction and regulated by the National Commission on Competition & Markets (CNMC).',
      SOCIAL: [],
    },
    NAVIGATION: {
      MAIN: {
        HOME: 'Thuis',
      },
      SITE_MENU: [{ title: 'Abonneren', href: PATHS.subscribe }],
      UTIL_MENU: [
        {
          title: process.env.REACT_APP_AD_CHOICES ?? '',
          href: process.env.REACT_APP_AD_CHOICES_URL ?? '',
        },
        { title: 'Voorwaarden', href: 'https://www.nbcuniversal.com/terms' },
        {
          title: 'Privacybeleid',
          href: 'https://www.nbcuniversal.com/privacy-policies/privacy-dutch',
        },
        {
          title: 'Waarden van toeleveringsketen',
          href: 'https://corporate.comcast.com/values/integrity/supply-chain-values',
        },
      ],
      ADMIN_MENU: [],
    },
  },
  UI: {
    SUBSCRIBE_BTN: 'Abonneren',
  },
  PAGES: {
    HOME: {
      TITLE: 'EPG',
    },
    NOT_FOUND: {
      TITLE: 'Oh nee! Pagina niet gevonden',
      MESSAGE: 'Pagina niet gevonden',
    },
    SUBSCRIBE: {
      TITLE: 'Abonneren',
      NO_AFFIILIATES_MESSAGE:
        'Er zijn geen Partners geregistreerd voor het geselecteerde land.',
    },
    TERMS: {
      TITLE: 'Algemene voorwaarden',
    },
    COMPETITION_RULES: {
      TITLE: '',
    },
    LEGAL_NOTICE: {
      TITLE: '',
    },
    TERMS_OF_SALE: {
      TITLE: '',
    },
  },
  SCHEDULE: {
    TITLE: 'Planning',
    MORNING: 'Ochtend',
    AFTERNOON: 'Middag',
    EVENING: 'Avond',
    ON_AIR: 'Nu aan',
    NO_CONTENT: 'Geen planningsinhoud beschikbaar',
    SEASON: 'Seizoen',
    EPISODE: 'Aflevering',
    DOWNLOAD_BTN_TEXT: 'Schema downloaden',
  },
  DATE: {
    DAYS: [
      'Zondag',
      'Maandag',
      'Dinsdag',
      'Woensdag',
      'Donderdag',
      'Vrijdag',
      'Zaterdag',
    ],
    MONTHS: [
      'Januari',
      'Februari',
      'Maart',
      'April',
      'Kunnen',
      'Juni-',
      'Juli-',
      'Augustus',
      'September',
      'Oktober',
      'November',
      'December',
    ],
  },
  COOKIE_BANNER: {
    UI: {
      POPUP_BACKGROUND: '#000000',
      POPUP_TEXT: '#ffffff',
      BUTTON_BACKGROUND: '#000000',
      BUTTON_TEXT: '#ffffff',
    },
    COPY: {
      MESSAGE:
        'Deze website maakt gebruik van cookies om ervoor te zorgen dat u de beste ervaring op onze website krijgt.',
      DISMISS: 'OK',
      LINK: 'Leer meer',
      HREF: 'https://www.nbcuniversal.com/privacy/cookies',
    },
  },
  AFFILIATES: [],
  TERMS: '',
  COMPETITION_RULES: '',
  TERMS_OF_SALE: '',
  LEGAL_NOTICE: '',
};
