import React, { useEffect } from 'react';
import {
  Heading,
  ContentPageLayout,
  ContentRowsLayout,
} from '@nbcuniversal-paint/nbcu-react-components';
import Page from '../../components/Page';

const Cookies = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = process.env.REACT_APP_CDN_SRC || '';
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.setAttribute(
      'data-domain-script',
      process.env.REACT_APP_COOKIE_ID || '',
    );
    script.integrity = process.env.REACT_APP_COOKIE_INTEG || '';
    script.crossOrigin = 'anonymous';
    script.setAttribute('data-document-language', 'true');

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Page title={'Cookies'}>
      <ContentPageLayout>
        <ContentRowsLayout>
          <Heading tag="h1" className="h1">
            {'Cookies'}
          </Heading>
          {/* OneTrust Cookies List start */}
          <div style={{ padding: '0 8px' }} id="ot-sdk-cookie-policy"></div>
          {/* OneTrust Cookies List end  */}
        </ContentRowsLayout>
      </ContentPageLayout>
    </Page>
  );
};

export default Cookies;
