import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './LogoMono.module.scss';
import Logo1 from './13thstreet/13_eme_rue.png';
import Logo2 from './syfywire/syfy.png';
import Logo3 from './diva/logo-white.svg';
import Logo4 from './dreamworks/logo-white.svg';
import Logo5 from './universal/logo-white.svg';
import Logo6 from './scifiwire/logo-white.svg';
import Logo7 from './13ulica/logo-white.svg';

const logos: any = {
  '13emerue': Logo1,
  '13-ulica': Logo7,
  'diva-universal': Logo3,
  dwnl: Logo4,
  'sci-fi-poland': Logo6,
  'sci-fi-serbia': Logo6,
  'sci-fi-slovenia': Logo6,
  syfy: Logo2,
  unau: Logo5,
};

interface LogoMonoProps {
  width?: string;
}

const LogoMono = ({ width }: LogoMonoProps) => {
  const navigate = useNavigate();

  const appName = process.env.REACT_APP_EPGNAME;

  const renderLogo = () => {
    return (
      <img
        src={logos[appName!]}
        className={styles.logo}
        style={{ width: `${width}px` }}
        alt="Logo"
      />
    );
  };

  return (
    <button
      onClick={() => {
        navigate('/');
      }}
    >
      {renderLogo()}
    </button>
  );
};

export default LogoMono;
