import React, { useEffect } from 'react';
import {
  Heading,
  Type,
  ContentPageLayout,
  ContentRowsLayout,
} from '@nbcuniversal-paint/nbcu-react-components';
import renderHTML from 'html-react-parser';
import Page from '../../components/Page';
import strings from '../../translations';

const { TITLE } = strings.PAGES.COMPETITION_RULES;
const { COMPETITION_RULES } = strings;

const Competition = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = process.env.REACT_APP_CDN_SRC || '';
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.setAttribute(
      'data-domain-script',
      process.env.REACT_APP_COOKIE_ID || '',
    );
    script.integrity = process.env.REACT_APP_COOKIE_INTEG || '';
    script.crossOrigin = 'anonymous';
    script.setAttribute('data-document-language', 'true');

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Page title={TITLE}>
      <ContentPageLayout>
        <ContentRowsLayout>
          <div>
            <Heading tag="h1" className="h1">
              {TITLE}
            </Heading>
          </div>
          <Type align="left">{renderHTML(COMPETITION_RULES)}</Type>
        </ContentRowsLayout>
      </ContentPageLayout>
    </Page>
  );
};

export default Competition;
