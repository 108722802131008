import React from 'react';
import { useNavigate } from 'react-router-dom';

const RedirectToHome = (): React.ReactElement | null => {
  const navigate = useNavigate();
  navigate('/');
  return null;
};

export default RedirectToHome;
