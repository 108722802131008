import styles from './BannerImage.module.scss';

type Props = {
  image: string;
};

const BannerImage = ({ image }: Props): JSX.Element => {
  return (
    <img
      className={styles.layout}
      src={image}
      style={{ height: 'calc(100vh-140px)' }}
    />
  );
};

export default BannerImage;
