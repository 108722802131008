import {
  createStore,
  applyMiddleware,
  compose,
  StoreEnhancer,
  Middleware,
  AnyAction,
  StoreEnhancerStoreCreator,
} from 'redux';
import { createLogger as reduxLogger, ReduxLoggerOptions } from 'redux-logger';
import { combineReducers } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import schedule from '../modules/schedule/schedule.reducer';

// A nice helper to tell us if we're on the server
export const isServer = !(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

const rootReducer = combineReducers({
  schedule,
});

// Ref: https://stackoverflow.com/questions/12709074/how-do-you-explicitly-set-a-new-property-on-window-in-typescript
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: () => StoreEnhancer<any, any>;
    __PRELOADED_STATE__?: any;
  }
}

const configureStore = () => {
  const middleware: Middleware<object, any, any>[] = [
    thunk as ThunkMiddleware<object, AnyAction>,
  ];

  const enhancers: StoreEnhancer<any, any>[] = [];

  // Dev tools are helpful
  if (process.env.NODE_ENV === 'development' && !isServer) {
    if (typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function') {
      enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const logger = reduxLogger({
      collapsed: true,
    } as ReduxLoggerOptions) as Middleware<object, any, any>;

    middleware.push(logger);
  }

  const composedEnhancers: StoreEnhancer<any, any> = compose(
    applyMiddleware(...middleware),
    ...enhancers,
  );

  // Do we have preloaded state available? Great, save it.
  const initialState = !isServer ? window.__PRELOADED_STATE__ : {};

  // Delete it once we have it stored in a variable
  if (!isServer) {
    delete window.__PRELOADED_STATE__;
  }

  // Create the store
  return createStore(rootReducer, initialState, composedEnhancers);
};

// Create a store and get back itself and its history object
export const store = configureStore();
