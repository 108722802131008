import React, { useState, Fragment } from 'react';
import cx from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import {
  Calendar,
  CloseCircle,
} from '@nbcuniversal-paint/nbcu-react-components';
import { isMobile } from 'react-device-detect';
import styles from './ScheduleNav.module.scss';
import { getIsoDate, datePlus, dateMinus } from '../../tools/dateFuncs';
import NavLink from '../../utils/RouterLink';
import NavDatePicker from '../NavDatePicker';

type Props = {
  className?: string | null;
  currentDate: string;
  callBack: (event: React.MouseEvent<HTMLButtonElement> | null) => void;
};

const ScheduleNav = ({
  className = null,
  currentDate,
  callBack,
}: Props): React.ReactElement => {
  const [activeDate, setActiveDate] = useState<string>(String(currentDate));
  const [showDatePicker, setShowDatePicker] = useState(false);

  const updateActiveDate = (
    event: React.MouseEvent<HTMLButtonElement>,
    manualDate = null,
  ) => {
    const newDate =
      manualDate || event?.currentTarget?.dataset?.date || activeDate;
    setActiveDate(newDate);
    setShowDatePicker(false);
    callBack(event);
  };

  const handleShowDatePicker = () => {
    setShowDatePicker(true);
  };

  const handleHideDatePicker = () => {
    setShowDatePicker(false);
  };

  const createNav = () => {
    const days = !isMobile
      ? [
          dateMinus(2, getIsoDate(currentDate)),
          dateMinus(1, getIsoDate(currentDate)),
          datePlus(0, getIsoDate(currentDate)),
          datePlus(1, getIsoDate(currentDate)),
          datePlus(2, getIsoDate(currentDate)),
        ]
      : [
          dateMinus(1, getIsoDate(currentDate)),
          datePlus(0, getIsoDate(currentDate)),
          datePlus(1, getIsoDate(currentDate)),
        ];

    const NavLinks = days.map(item => {
      const { day, displayDate, formattedDate } = item;
      if (String(currentDate) === formattedDate) {
        return (
          <Fragment key={uuidv4()}>
            <div className={cx('schedule-nav--today', styles.listitem)}>
              <div className={cx(styles.active, styles.link)}>
                <div className={styles.day}>{day}</div>
                <div className={styles.displaydate}>{displayDate}</div>
              </div>
            </div>
            <div className={styles.listitem}>
              <button
                type="button"
                onClick={handleShowDatePicker}
                className={styles.button}
                aria-label="calendar-button"
              >
                <Calendar className={styles.calicon} />
              </button>
            </div>
          </Fragment>
        );
      }
      return (
        <button
          type="button"
          key={uuidv4()}
          className={cx('schedule-nav--tab', styles.listitem)}
          data-date={formattedDate}
          onClick={updateActiveDate}
        >
          <NavLink href={`/schedule/${formattedDate}`} className={styles.link}>
            <div className={styles.day}>{day}</div>
            <div className={styles.displaydate}>{displayDate}</div>
          </NavLink>
        </button>
      );
    });

    return (
      <div>
        <div
          className={cx(
            { [styles.list]: !isMobile },
            { [styles['list-mobile']]: isMobile },
          )}
        >
          {NavLinks}
        </div>
      </div>
    );
  };

  const nav = createNav();

  return (
    <>
      {showDatePicker && (
        <div className={styles.overlay}>
          <div>
            <div className={styles.closebutton}>
              <button type="button" onClick={handleHideDatePicker}>
                <CloseCircle />
              </button>
            </div>

            <NavDatePicker callBack={callBack} hide={handleHideDatePicker} />
          </div>
        </div>
      )}

      <div className={cx(className, styles.layout)}>
        <div className={styles.wrapper}>
          <div className={cx('schedule-nav', styles.nav)}>{nav}</div>
        </div>
      </div>
    </>
  );
};

export default ScheduleNav;
