import React from 'react';
import cx from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { Container, Heading } from '@nbcuniversal-paint/nbcu-react-components';
import LogoMono from '../LogoMono';
import styles from './Footer.module.scss';
import strings from '../../../translations';
import Social from '../Social';
import { useNavigate } from 'react-router';

const Footer = (): React.ReactElement => {
  const navigate = useNavigate();

  const {
    COPYRIGHT_TEXT,
    NBC,
    UTIL_MENU_TITLE,
    ADMIN_MENU_TITLE,
    CONTACT_TEXT,
    COMMISSION_DETAILS,
    CNBC_COPY,
    SOCIAL,
  } = strings.GLOBAL.FOOTER;
  const { SITENAME } = strings.GLOBAL;
  const { UTIL_MENU, ADMIN_MENU, SITE_MENU } = strings.GLOBAL.NAVIGATION;

  const UtilMenu: JSX.Element[] = UTIL_MENU.map(link => {
    return (
      <li key={uuidv4()} className={styles.listitem}>
        <a
          className={styles.menulink}
          style={{ cursor: 'pointer' }}
          target="_blank"
          rel="noopener noreferrer"
          href={link.href}
        >
          {link.title}
        </a>
      </li>
    );
  });

  const AdminMenu: JSX.Element[] = ADMIN_MENU.map(link => {
    return (
      <li key={uuidv4()} className={styles.listitem}>
        <a
          className={styles.menulink}
          style={{ cursor: 'pointer' }}
          target="_blank"
          rel="noopener noreferrer"
          href={link.href}
        >
          {link.title}
        </a>
      </li>
    );
  });

  interface Link {
    title: string;
    href: string;
    external?: boolean;
  }

  const SiteMenu = SITE_MENU.map((link: Link) => {
    if (link.external) {
      return (
        <li key={uuidv4()} className={styles.listitem}>
          <a
            style={{ cursor: 'pointer' }}
            href={link.href}
            rel="noopener noreferrer"
            target="_blank"
          >
            {link.title}{' '}
          </a>
        </li>
      );
    }

    if (!link.external) {
      return (
        <li key={uuidv4()} className={styles.listitem}>
          <button
            style={{ padding: '0px', cursor: 'pointer' }}
            onClick={() => {
              navigate(link.href);
              window.location.reload();
            }}
            className={cx('epg-link', styles.menulink)}
          >
            {link.title}
          </button>
        </li>
      );
    }
  });

  return (
    <footer className={cx('footer', styles.footer)}>
      <Container className={styles.container}>
        <div className={styles.menus}>
          <div className={styles.wrapper}>
            <Heading tag="h4" className={cx('h4', styles.footerHeading)}>
              {UTIL_MENU_TITLE}
            </Heading>
            <ul className={styles.menulist}>{SiteMenu}</ul>
            {/* OneTrust Cookies Settings button start */}
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              Cookie Settings
            </button>
            {/* OneTrust Cookies Settings button end */}
            <ul className={styles.menulist}>{UtilMenu}</ul>
          </div>
          <div />
          <div />
          <div className={styles.wrapper}>
            <Heading tag="h4" className={cx('h4', styles.footerHeading)}>
              {ADMIN_MENU_TITLE}
            </Heading>
            <ul className={styles.menulist}>{AdminMenu}</ul>
          </div>
        </div>
      </Container>
      <Container className={styles.container}>
        <div className={styles.layout}>
          <div className={styles.logo}>
            <LogoMono width="140" />
          </div>
          <div>
            <Social links={SOCIAL} />
          </div>
        </div>
        <div className={cx('footer-copyright', styles.copyright)}>
          <span>
            {COPYRIGHT_TEXT.replace(
              '{year}',
              new Date().getFullYear().toString(),
            ).trim()}
          </span>
        </div>
        {CONTACT_TEXT && (
          <div className={cx(styles.copyright, styles.legal)}>
            <p>
              {CONTACT_TEXT}
              <br />
              {COMMISSION_DETAILS}
              <br />
              {SITENAME}&nbsp;{CNBC_COPY}
            </p>
          </div>
        )}
        <div className={styles.copyright}>{NBC}</div>
      </Container>
    </footer>
  );
};

export default Footer;
