export default {
  home: '/',
  schedule: '/schedule/:id',
  contact: '/contact',
  about: '/about',
  terms: '/terms-conditions',
  competition: '/competition-rules',
  termsSale: '/terms-of-sale',
  legal: '/legal',
  subscribe: '/subscribe',
  oldschedule: '/schedule/day/today',
  cookies: '/cookies',
};
