import { scheduleKey as KEY } from '../../schemas/config';
import normalise from '../../schemas/normalizr';
import { AWS_ENDPOINT, EPG_REGION } from '../../constants';
import * as TYPES from './schedule.types';
import selectScheduleItems from './schedule.selectors';
import { Dispatch } from 'redux';
import { State } from './schedule';

type GetState = () => State;

/**
 * FetchScheduleData from API and spread into store.
 *
 * @param {string} date
 */
export default function fetchScheduleData(date: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    // Return from state if it already exists.
    const state = getState();
    const scheduleItems = selectScheduleItems(state, date);
    if (scheduleItems && scheduleItems.length > 0) {
      dispatch({
        type: TYPES.EXIST,
      });
      return scheduleItems;
    }

    dispatch({
      type: TYPES.LOAD,
      payload: true,
    });

    try {
      const url = `${AWS_ENDPOINT}/${EPG_REGION}/${date}.json`;
      const response = await fetch(url);
      if (!response.ok) {
        await response.text();
        dispatch({
          type: TYPES.LOAD_FAIL,
        });
        return false;
      }
      if (response.ok) {
        const json = await response.json();
        const payload = normalise(json, KEY);
        dispatch({
          type: TYPES.ADDED,
          payload,
        });
      }
      return true;
    } catch (error) {
      if (error instanceof TypeError) {
        return new Error(error.message);
      }
    }
  };
}
