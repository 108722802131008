import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Link,
  history,
} from '@nbcuniversal-paint/nbcu-react-components';

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  type: 'Link' | 'Button';
  href: string;
  className?: string;
};

// A simple component that shows the path name of the current location
const RouterLink = ({
  children,
  type,
  onClick,
  href,
  className,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const changeHandler = () => {
    if (typeof onClick === 'function') {
      onClick();
      return;
    }
    if (href) {
      navigate(href);
    }
  };

  if (type === 'Link') {
    return (
      <Link
        history={history}
        href={href}
        onClick={changeHandler}
        className={className}
      >
        {children}
      </Link>
    );
  }
  return (
    <Button onClick={changeHandler} text={children} className={className} />
  );
};

RouterLink.defaultProps = {
  type: 'Link',
};

export default RouterLink;
