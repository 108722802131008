import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import cx from 'classnames';
import {
  Card,
  Heading,
  Button,
  ContentPageLayout,
  ContentRowsLayout,
} from '@nbcuniversal-paint/nbcu-react-components';
import Page from '../../components/Page';
import { S3_STATIC_ASSETS_URL } from '../../constants';
import strings from '../../translations';
import styles from './Subscribe.module.scss';

const { TITLE, NO_AFFIILIATES_MESSAGE } = strings.PAGES.SUBSCRIBE;
const { AFFILIATES, UI } = strings;

const Subscribe = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = process.env.REACT_APP_CDN_SRC || '';
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.setAttribute(
      'data-domain-script',
      process.env.REACT_APP_COOKIE_ID || '',
    );
    script.integrity = process.env.REACT_APP_COOKIE_INTEG || '';
    script.crossOrigin = 'anonymous';
    script.setAttribute('data-document-language', 'true');

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  if (!AFFILIATES || AFFILIATES.length === 0) {
    return (
      <Page title={TITLE}>
        <ContentPageLayout>
          <ContentRowsLayout>
            <div>
              <Heading tag="h1" className={cx('h1', styles.heading)}>
                {TITLE}
              </Heading>
            </div>
            <p>{NO_AFFIILIATES_MESSAGE}</p>
          </ContentRowsLayout>
        </ContentPageLayout>
      </Page>
    );
  }

  const AffiliatesRender = AFFILIATES.map(item => {
    return (
      <a
        key={uuidv4()}
        target="_blank"
        rel="noopener noreferrer"
        href={item.LINK}
        className={styles.cardwrapper}
      >
        <Card size="small" className={cx('affiliate-card')} align="center">
          <div
            style={{
              height: '185px',
              background: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '20px',
            }}
          >
            <img
              src={`${S3_STATIC_ASSETS_URL}/${item.IMAGENAME}`}
              alt=""
              style={{ width: '100%' }}
            />
          </div>

          {/* <Card.Media
            src={`${S3_STATIC_ASSETS_URL}/${item.IMAGENAME}`}
            alt={item.NAME}
            title={item.NAME}
            className={styles.media}
            aspectRatio="16/9"
          /> */}

          <Card.Content className={styles.layout}>
            <Heading tag="h3" className={cx('h4', styles.title)}>
              {item.NAME}
            </Heading>
            {item.SUBTITLE && (
              <div className={styles.subtitle}>{item.SUBTITLE}</div>
            )}
          </Card.Content>
          <Card.Footer className={styles.footer}>
            <Button
              tag="button"
              variants={['primary']}
              text={UI.SUBSCRIBE_BTN}
            />
          </Card.Footer>
        </Card>
      </a>
    );
  });
  return (
    <Page title={TITLE}>
      <ContentPageLayout>
        <ContentRowsLayout>
          <div>
            <Heading tag="h1" className={cx('h1', styles.heading)}>
              {TITLE}
            </Heading>
          </div>
          <div className={styles.grid}>{AffiliatesRender}</div>
        </ContentRowsLayout>
      </ContentPageLayout>
    </Page>
  );
};

export default Subscribe;
