import { normalize, schema } from 'normalizr';
import { scheduleKey } from '../config';

// Default `id` for API responses.
const idAttribute = 'uuid';

/** Reusable schemas. */
const scheduleSchema = new schema.Entity(`${scheduleKey}`, {}, { idAttribute });

/**
 * Apply a normalizr schema to a data structure.
 *
 * @param {object} data
 * @param {object} useSchema
 * @returns {object} A normalized Object.
 */
export const applySchema = (data, useSchema) => {
  // Handle if `data` is an Object or Array of Objects.
  const appliedSchema = Array.isArray(data)
    ? new schema.Array(useSchema)
    : useSchema;
  return normalize(data, appliedSchema);
};

/**
 * Normalise data with a default schema.
 *
 * @param {(Array|object)} data
 * @param {string} key
 * @returns {object}
 */
export const normalizeApiData = (data, key) => {
  const dataSchema = new schema.Entity(
    key,
    {
      [scheduleKey]: scheduleSchema,
    },
    {
      idAttribute,
    },
  );
  return applySchema(data, dataSchema);
};

export default normalizeApiData;
