import React from 'react';
import {
  Heading,
  Type,
  ContentPageLayout,
  ContentRowsLayout,
} from '@nbcuniversal-paint/nbcu-react-components';
import Page from '../../components/Page';
import strings from '../../translations';

const { TITLE, MESSAGE } = strings.PAGES.NOT_FOUND;

const NotFound = () => (
  <Page title={TITLE}>
    <ContentPageLayout>
      <ContentRowsLayout>
        <Heading tag="h1" className="sr-only">
          {TITLE}
        </Heading>
        <Type align="center">{MESSAGE}</Type>
      </ContentRowsLayout>
    </ContentPageLayout>
  </Page>
);

export default NotFound;
