import { Action, ScheduleState } from './schedule';
import * as TYPES from './schedule.types';

// Initial state.
export const initialState = {
  entities: {},
  loading: false,
  error: false,
};

// Reducer.
export default (state: ScheduleState = initialState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case TYPES.ADDED:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...payload.entities.schedule,
        },
        loading: false,
      };
    case TYPES.LOAD:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case TYPES.LOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case TYPES.EXIST:
      return {
        ...state,
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};
