import PATHS from '../routes/paths';

export default {
  GLOBAL: {
    SITENAME: '13 Ulica Polska',
    FOOTER: {
      COPYRIGHT_TEXT:
        '© {year} NBC Universal Global Networks España S.L.U.  All rights reserved.',
      NBC: 'Torre Europa, Paseo de la Castellana 95, Planta 10, 28046 Madrid, SPAIN RCS: B-82227893 MADRID',
      UTIL_MENU_TITLE: 'Przydatne linki',
      ADMIN_MENU_TITLE: 'Oferta NBCUniversal',
      CONTACT_TEXT: 'Contact us by e-mail: contact.13ulicaPL@nbcuni.com',
      COMMISSION_DETAILS:
        'NBC Universal Global Networks España S.L.U. is wholly owned by Universal Studios International BV',
      CNBC_COPY:
        'is subject to the Spanish jurisdiction and regulated by the National Commission on Competition & Markets (CNMC).',
      SOCIAL: [],
    },
    NAVIGATION: {
      MAIN: {
        HOME: 'Home',
      },
      SITE_MENU: [
        { title: 'Znajdź operatora', href: PATHS.subscribe },
        { title: 'Regulamin', href: PATHS.terms },
      ],
      UTIL_MENU: [
        {
          title: process.env.REACT_APP_AD_CHOICES ?? '',
          href: process.env.REACT_APP_AD_CHOICES_URL ?? '',
        },
        {
          title: 'Polityka Prywatności',
          href: 'https://www.nbcuniversal.com/privacy-policies/privacy-polish',
        },
      ],
      ADMIN_MENU: [
        { title: 'SCI FI', href: 'https://www.scifiuniversal.pl' },
        { title: '13 ULICA', href: 'http://www.13ulica.pl/' },
        { title: 'E! ENTERTAINMENT', href: 'http://www.eonline.com/' },
      ],
    },
  },
  UI: {
    SUBSCRIBE_BTN: 'Sprawdź',
  },
  PAGES: {
    HOME: {
      TITLE: 'EPG',
    },
    NOT_FOUND: {
      TITLE: 'O nie! Strona nie znaleziona',
      MESSAGE: 'Strona nie znaleziona',
    },
    SUBSCRIBE: {
      TITLE: 'Wybierz operatora',
      NO_AFFIILIATES_MESSAGE:
        'Brak Partnerów zarejestrowanych dla wybranego kraju.',
    },
    TERMS: {
      TITLE: 'Warunki użytkowania',
    },
    COMPETITION_RULES: {
      TITLE: '',
    },
    LEGAL_NOTICE: {
      TITLE: '',
    },
    TERMS_OF_SALE: {
      TITLE: '',
    },
  },
  SCHEDULE: {
    TITLE: 'Program TV',
    MORNING: 'Rano',
    AFTERNOON: 'Popołudnie',
    EVENING: 'Wieczór',
    ON_AIR: 'Na Antenie',
    NO_CONTENT: 'Brak dostępnych treści harmonogramu',
    SEASON: 'Sezon',
    EPISODE: 'Epizod',
    DOWNLOAD_BTN_TEXT: 'Pobierz ramówkę',
  },
  DATE: {
    DAYS: [
      'Niedziela',
      'Poniedziałek',
      'Wtorek',
      'Środa',
      'Czwartek',
      'Piątek',
      'Sobota',
    ],
    MONTHS: [
      'Styczeń',
      'Luty',
      'Marsz',
      'Kwiecień',
      'Może',
      'Czerwiec',
      'Lipiec',
      'Sierpień',
      'Wrzesień',
      'Październik',
      'Listopad',
      'Grudzień',
    ],
  },
  COOKIE_BANNER: {
    UI: {
      POPUP_BACKGROUND: '#000000',
      POPUP_TEXT: '#ffffff',
      BUTTON_BACKGROUND: '#ffffff',
      BUTTON_TEXT: '#000000',
    },
    COPY: {
      MESSAGE:
        'Ta strona korzysta z plików cookie, aby zapewnić Ci najlepszą jakość na naszej stronie.',
      DISMISS: 'Dobrze',
      LINK: 'Ucz się więcej',
      HREF: 'https://www.nbcuniversal.com/privacy/cookies',
    },
  },
  AFFILIATES: [
    {
      NAME: 'Polsat Box',
      SUBTITLE: 'Kanał: 51',
      LINK: 'http://www.cyfrowypolsat.pl/',
      IMAGENAME: 'Polsat_Box_small_scale_logo_horizontal.png',
    },
    {
      NAME: 'CANAL+',
      SUBTITLE: 'Kanał: 107',
      LINK: 'https://pl.canalplus.com/',
      IMAGENAME: 'canalplus.jpg',
    },
    {
      NAME: 'Vectra',
      SUBTITLE: 'Kanał: 311',
      LINK: 'https://www.vectra.pl/',
      IMAGENAME: 'vectra.jpg',
    },
    {
      NAME: 'Multimedia',
      SUBTITLE: 'Kanał: 158',
      LINK: 'https://www.multimedia.pl/',
      IMAGENAME: 'multimedia.jpg',
    },
    {
      NAME: 'Toya',
      SUBTITLE: 'Kanał: 124',
      LINK: 'https://toya.net.pl/',
      IMAGENAME: 'toya.jpg',
    },
    {
      NAME: 'Orange',
      SUBTITLE: 'Kanał: 228',
      LINK: 'https://www.orange.pl/',
      IMAGENAME: 'orange.jpg',
    },
    {
      NAME: 'Netia',
      SUBTITLE: 'Kanał: 131',
      LINK: 'https://www.netia.pl/',
      IMAGENAME: 'netia.jpg',
    },
    {
      NAME: 'East & West',
      SUBTITLE: 'Kanał: 138',
      LINK: 'http://eastwest.com.pl/',
      IMAGENAME: 'eaw_logo.jpg',
    },
    {
      NAME: 'SatFilm',
      SUBTITLE: 'Kanał: 27',
      LINK: 'http://satfilm.pl/',
      IMAGENAME: 'satfilm.jpg',
    },
    {
      NAME: 'Telewizja Kablowa Koszalin',
      SUBTITLE: 'Kanał: 9',
      LINK: 'https://www.tkk.net.pl/',
      IMAGENAME: 'tkk.jpg',
    },
    {
      NAME: 'Chopin Telewizja Kablowa',
      SUBTITLE: 'Kanał: 228',
      LINK: 'https://tkchopin.pl/',
      IMAGENAME: 'chopin.jpg',
    },
    {
      NAME: 'Elsat',
      SUBTITLE: 'Kanał: 238',
      LINK: 'https://sileman.pl/home/',
      IMAGENAME: 'elsat.jpg',
    },
    {
      NAME: 'UPC',
      SUBTITLE: `kanał 253 UPC 4K BOX/Horizon, kanał 500 Mediabox`,
      LINK: 'https://www.upc.pl/telewizja/kup-telewizje/',
      IMAGENAME: 'UPC_CIRCLE_sRGB.png',
    },
    {
      NAME: 'WP Pilot',
      SUBTITLE: '',
      LINK: 'https://pilot.wp.pl/tv/',
      IMAGENAME: 'WP-Pilot_logo_color.png',
    },
    {
      NAME: 'Play',
      SUBTITLE: '',
      LINK: 'https://www.play.pl/nowy/oferta/play-telewizja',
      IMAGENAME: 'play_new_logo.png',
    },
    {
      NAME: 'JMDI',
      SUBTITLE: 'Kanał: 50',
      LINK: 'https://jmdi.pl/',
      IMAGENAME: 'RGB_logo_JMDI_color_claim.png',
    },
    {
      NAME: 'Metroport',
      SUBTITLE: 'Kanał: 170',
      LINK: 'https://metroport.pl/',
      IMAGENAME: 'metroport-logo-color.png',
    },
  ],
  TERMS: `
   <div class="copy__text">
  <p>
    Korzystanie z tej Witryny podlega Warunkom świadczenia usług
    <a href="https://www.nbcuniversal.com/terms" target="_blank" rel="noopener noreferrer"
      >https://www.nbcuniversal.com/terms</a
    >, które uzupełniają niniejsze Warunki.
  </p>
  <p>
    Jesteś na witrynie internetowej kanału13 Ulica obsługiwanej przez firmę NBC
    Universal Global Networks España S.L.U. ("Universal"). Korzystanie przez
    Ciebie z niniejszej witryny (łącznie z jej zawartością i wszelkimi innymi
    usługami świadczonymi okresowo) podlega następującym Warunkom Użytkowania.
    Uznajemy, że wyrażasz na nie zgodę wchodząc na Witrynę i/lub rejestrując się
    na niej.
  </p>
  <p>
    Dokonując rejestracji na www.13ulica.pl wyrażasz zgodę na otrzymywanie
    poniższych zawiadomień:
  </p>
  <p>
    - Jeśli zdecydowałeś się na otrzymywanie dodatkowych informacji na temat
    kanału 13 Ulica, skontaktujemy się z Tobą za pomocą poczty elektronicznej
    lub prześlemy wiadomości SMS ze szczegółowymi informacjami o innych
    programach, wydarzeniach i konkursach związanych z kanałem 13 Ulica. - Jeśli
    zdecydowałeś się na otrzymywanie dodatkowych informacji od komercyjnych
    partnerów firmy Universal, skontaktują się oni z Tobą za pomocą poczty
    elektronicznej lub prześlą wiadomości SMS ze szczegółowymi informacjami o
    programach, wydarzeniach i konkursach zewnętrznego partnera komercyjnego. -
    Prosimy o zapoznanie się z naszymi Zasadami Prywatności, aby dowiedzieć się,
    jak postępujemy z Twoimi danymi osobowymi.
  </p>
  <p>
    Anulowanie subskrypcjiwww.13ulica.pl - Możesz zrezygnować z otrzymywania
    wszelkich zawiadomień zwww.13ulica.pl, klikając na łącze „Anuluj
    subskrypcję” zawarte w każdej otrzymanej wiadomości email lub wysyłając
    pocztą elektroniczną prośbę o anulowanie subskrypcji na adres
    contact.13ulicaPL@nbcuni.com.
  </p>
  <p>
    Prawo do zawartości Prawa autorskie oraz wszelkie inne prawa do materiałów
    zamieszczonych na Witrynie należą do firmy Universal lub materiały te
    zamieszczono za zgodą właściciela praw autorskich. Jako osoba odwiedzająca
    Witrynę możesz pobrać jedną kopię materiału, aby go samodzielnie obejrzeć.
    Bez naszego wyraźnego pisemnego pozwolenia nie masz prawa dokonywać
    dekompilacji, odwrotnej inżynierii, demontażu, wynajmowania, dzierżawienia,
    pożyczania, sublicencjonowania ani tworzenia produktów pochodnych na
    podstawie niniejszej Witryny, włączając w to wszelkie informacje i
    oprogramowanie udostępnione poprzez Witrynę. Jeśli Warunki Użytkowania nie
    przewidują inaczej, nie masz prawa kopiować, zapisywać, pobierać,
    modyfikować, reprodukować, ponownie publikować, rozpowszechniać ani
    wykorzystywać niniejszej Witryny oraz/lub zawartych na niej informacji w
    jakichkolwiek celach komercyjnych lub niekomercyjnych, w stopniu innym niż
    konieczny do wyświetlenia, korzystania i nawigowania po Witrynie.
  </p>
  <p>
    Informacje, dostępność i łącza z innymi witrynami Mimo, że firma Universal
    dokłada wszelkich starań, aby informacje na Witrynie były pełne i dokładne,
    część informacji pochodzi od dostawców zewnętrznych. Firma Universal nie
    ponosi odpowiedzialności za: usługi lub informacje zamieszczone na Witrynie
    pochodzące od dostawców zewnętrznych; za dostępność innych Witryn; za
    jakiekolwiek materiały, reklamy, transakcje, produkty lub usługi dostępne na
    Witrynie lub udostępniane przez dostawców zewnętrznych lub przez witryny lub
    inne materiały, do których docierasz za pośrednictwem Witryny; za żadne
    szkody, straty lub łamanie prawa domniemane lub spowodowane przez lub w
    związku z wykorzystywaniem lub bazowaniem na materiałach, reklamie,
    produktach lub usługach udostępnianych przez stronę zewnętrzną lub
    dostępnych poprzez witryny zewnętrzne lub inne materiały. Wszelkie kontakty
    pomiędzy Tobą i jakąkolwiek stroną trzecią, z którą nawiązałeś kontakt na
    Witrynie lub poprzez Witrynę, włączając w to płatności za produkty i dostawy
    produktów, usług oraz wszelkie inne warunki, zastrzeżenia, gwarancje lub
    zażalenia wynikające z tych kontaktów, są wyłącznie sprawą między Tobą i
    daną stroną trzecią. Przyjmując niniejsze Warunki Użytkowania, zgadzasz się,
    aby nie obarczać nas odpowiedzialnością za jakiekolwiek straty lub szkody
    powstałe w wyniku tych kontaktów i nie angażować nas w spory między Tobą a
    stroną trzecią. Firma Universal nie ponosi żadnej odpowiedzialności
    wynikającej z nieścisłości lub pominięcia informacji dostępnych na Witrynie
    lub poprzez nią, nie ponosi również odpowiedzialności za przerwę w działaniu
    lub dostępie do Witryny.
  </p>
  <p>
    Zawieszenie działania Witryny Zastrzegamy sobie prawo do: - odmowy lub
    zawieszenia Twojego dostępu do Witryny lub dowolnego działu Witryny za
    uprzednim powiadomieniem lub bez uprzedniego powiadomienia, jeśli korzystasz
    z Witryny lub jej części w sposób, który uznamy za obraźliwy, przesadny,
    niezgodny z interesem innych użytkowników Witryny lub naruszający niniejsze
    WarunkiUżytkowania; - usunięcie, w stosownych przypadkach, z uprzednim
    powiadomieniem lub bez takiego powiadomienia, wszelkich materiałów lub
    materiału przekazanego na Witrynę, jeśli będziemy zdania, że może on być
    sprzeczny z niniejszymi Warunkami Użytkowania. Ponadto zastrzegamy sobie
    prawo zawieszenia, ograniczenia lub zawieszenia dostępu do niniejszej
    Witryny z dowolnego powodu w dowolnym czasie.
  </p>
  <p>
    Ograniczenie odpowiedzialności W najwyższym dopuszczalnym przez prawo
    stopniu, za wyjątkiem spowodowania śmierci lub obrażeń ciała wskutek naszych
    zaniedbań, wykluczamy odpowiedzialność za wszelkie roszczenia, straty,
    żądania i szkody powstałe bezpośrednio lub pośrednio z powodu Witryny lub w
    jakikolwiek sposób powiązane z nią oraz/lub z jakimikolwiek usługami
    oferowanymi okresowo przez Witrynę. Wykluczenie to stosuje się bez
    ograniczeń do przerw w świadczeniu usługi, utraty zysków, utraty kontraktów
    lub sposobności biznesowych, utraty danych lub jakichkolwiek innych szkód
    następczych, przypadkowych, specjalnych lub moralnych wynikających z
    użytkowania Witryny, nawet jeśli powiadomiono nas o możliwości powstania
    takich szkód, wynikających z kontraktu, deliktu, obowiązującego prawa lub z
    innego powodu.
  </p>
  <p>
    Promocje i konkursy Firma Universal (lub wybrana strona trzecia, za zgodą
    firmy) może na Witrynie ogłaszać konkursy, promocje lub inne oferty. Każda
    taka oferta będzie podlegała określonym, jasno sprecyzowanym warunkom i może
    nie być dostępna we wszystkich jurysdykcjach.
  </p>
  <p>
    Twoje zobowiązania Wyrażasz zgodę na korzystanie z Witryny wyłącznie w
    sposób zgodny z niniejszymi Warunkami Użytkowania i wszelkimi dodatkowymi
    warunkami w sposób zapewniający przestrzeganie wszystkich stosownych praw i
    zarządzeń (w tym także, bez ograniczenia, przestrzeganie prawa miejscowego).
    W szczególności nie będziesz korzystać z Witryny (ani z żadnej jej części)
    do przekazywania lub umieszczania jakichkolwiek materiałów o charakterze
    zniesławiającym, obraźliwym, nieprzyzwoitym lub zastraszającym lub
    materiałów, które naszym zdaniem mogą spowodować irytację, niedogodność,
    niepokój, zawierają wirus lub inną zaprogramowaną procedurę, która może
    spowodować uszkodzenie lub zakłócenia w działaniu Witryny oraz/lub usług
    świadczonych przez Witrynę. Zgadzasz się nie korzystać z Witryny w sposób,
    który mógłby spowodować przerwanie dostępu do całej Witryny lub jej części,
    uszkodzenie Witryny lub jej części, zmniejszenie efektywności lub
    upośledzenie jej w jakikolwiek sposób. W zakresie, w którym użytkowanie
    przez Ciebie lub upoważnioną przez Ciebie osobę, za którą ponosisz
    odpowiedzialność (np. jako pracodawca) wyrządzi nam lub komukolwiek innemu
    stratę lub szkodę, zgadzasz się natychmiast na żądanie wynagrodzić nam
    wszelkie straty lub szkody nam przypisywane, w razie potrzeby, łącznie z
    wszelkimi opłatami sądowymi, administracyjnymi lub technicznymi, które mogą
    wyniknąć z takiego korzystania.
  </p>
  <p>
    Twój wkład Kiedy przekazujesz jakiekolwiek materiały na Witrynę (łącznie z
    wszelkimi tekstami, materiałami graficznymi, wideo lub dźwiękowymi),
    przekazujesz firmie Universal wieczyste, bez honorarium, niewyłączne,
    podlegające sublicencji prawo oraz licencję na użytkowanie, reprodukcję,
    modyfikację, adaptację, publikację, tłumaczenie, tworzenie produktów
    pochodnych, rozpowszechnianie, wystawianie, odtwarzanie i korzystanie z
    wszelkich praw autorskich oraz praw do reklamy każdego z tych materiałów na
    całym świecie i/lub włączenia ich do innych prac lub mediów. Jeśli nie
    chcesz przekazać firmie Universal tych praw, sugerujemy nie umieszczać
    swojej pracy na Witrynie.
  </p>
  <p>
    Ponadto, umieszczając swoją pracę na Witrynie: - gwarantujesz, że jest ona
    Twoją oryginalną pracą; - zgadzasz się na pokrycie kosztów sądowych,
    odszkodowania i innych wydatków, które Universal może ponieść w wyniku
    naruszenia przez Ciebie powyższej gwarancji; - zrzekasz się wszelkich
    moralnych praw do swojej pracy w stosunku do wszystkich wymienionych tu
    zastosowań. W przypadku jakiegokolwiek konfliktu między niniejszymi
    Warunkami i Zastrzeżeniami a określonymi warunkami opublikowanymi w innej
    części Witryny, odnoszącymi się do określonego materiału, obowiązują te
    ostatnie.
  </p>
  <p>
    Ustalenia ogólne W celu stałego doskonalenia Witryny zastrzegamy sobie prawo
    do wprowadzania zmian do niniejszych Warunków Użytkowania wyłącznie według
    naszego uznania. Twoim obowiązkiem jest regularne sprawdzanie niniejszych
    Warunków Użytkowania. Jeśli zostanie stwierdzone, że dowolna część
    niniejszych Warunków i Zastrzeżeń jest niezgodna z prawem, nieważna lub nie
    można jej wprowadzić w życie ze względu na prawa obowiązujące w stanie lub w
    kraju, w którym takie Warunki i Zastrzeżenia miałyby obowiązywać, wówczas w
    zakresie, w którym są one nielegalne, nieważne lub nie da się ich wprowadzić
    w życie, zostaną one oddzielone i usunięte, a pozostałe Warunki i
    Zastrzeżenia pozostają w pełnej w mocy.
  </p>
  <p>
    Obowiązujące prawo Niniejsze Warunki Użytkowania podlegają wyłącznie prawu
    Anglii i Walii i będą interpretowane wyłącznie zgodnie z tym prawem. Sądy
    Anglii i Walii sprawują wyłączną jurysdykcję we wszelkich sporach, z tym
    zastrzeżeniem, że, według naszego uznania, przysługuje nam prawo do
    wszczęcia i przeprowadzenia postępowania sądowego w innej jurysdykcji.
  </p>
</div>
   `,
  COMPETITION_RULES: '',
  TERMS_OF_SALE: '',
  LEGAL_NOTICE: '',
};
