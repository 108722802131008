import React from 'react';
import {
  Instagram,
  FacebookBox,
  Twitter,
  Youtube,
} from '@nbcuniversal-paint/nbcu-react-components';
import Tiktok from '../../../assets/icons/tiktok.svg';
import styles from './Social.module.scss';

type SocialLink = {
  href: string;
  label: string;
};

interface IProps {
  links: SocialLink[];
}

const Social = ({ links }: IProps): JSX.Element | null => {
  if (!links || links.length === 0) {
    return null;
  }

  const iconSize = 24;
  return (
    <nav id="navigation" className={styles.layout}>
      {links.map((link, index) => {
        let icon;
        switch (link.label) {
          case 'Instagram':
            icon = <Instagram size={iconSize} color="#FFF" />;
            break;
          case 'Facebook':
            icon = <FacebookBox size={iconSize} color="#FFF" />;
            break;
          case 'Twitter':
            icon = <Twitter size={iconSize} color="#FFF" />;
            break;
          case 'Youtube':
            icon = <Youtube size={iconSize} color="#FFF" />;
            break;
          case 'Tiktok':
            icon = (
              <img
                src={Tiktok}
                style={{ width: '16px' }}
                color="#FFF"
                alt="Tiktok icon"
              />
            );
            break;
          default:
            icon = <Instagram size={iconSize} color="#FFF" />;
        }
        return (
          <div className={styles.navItem} key={index}>
            <a
              className={styles.navLink}
              href={link.href}
              target="_blank"
              rel="noreferrer"
            >
              {icon}
            </a>
          </div>
        );
      })}
    </nav>
  );
};

export default Social;
