import PATHS from '../routes/paths';

export default {
  GLOBAL: {
    SITENAME: 'Scifi Slovenija',
    FOOTER: {
      COPYRIGHT_TEXT:
        '© {year} NBC Universal Global Networks España S.L.U.  All rights reserved.',
      NBC: 'Torre Europa, Paseo de la Castellana 95, Planta 10, 28046 Madrid, SPAIN RCS: B-82227893 MADRID',
      UTIL_MENU_TITLE: 'Koristne povezave',
      ADMIN_MENU_TITLE: 'Sektor NBCUniversal',
      CONTACT_TEXT: 'Contact us by e-mail: contact.SCIFISI@nbcuni.com',
      COMMISSION_DETAILS:
        'NBC Universal Global Networks España S.L.U. is wholly owned by Universal Studios International BV',
      CNBC_COPY:
        'is subject to the Spanish jurisdiction and regulated by the National Commission on Competition & Markets (CNMC).',
      SOCIAL: [],
    },
    NAVIGATION: {
      MAIN: {
        HOME: 'Home',
      },
      SITE_MENU: [
        { title: 'Naročite se', href: PATHS.subscribe },
        { title: 'Pogoji uporabe', href: PATHS.terms },
      ],
      UTIL_MENU: [
        {
          title: process.env.REACT_APP_AD_CHOICES ?? '',
          href: process.env.REACT_APP_AD_CHOICES_URL ?? '',
        },
        {
          title: 'Pravilnik o zasebnosti',
          href: 'https://www.nbcuniversal.com/privacy-policies/privacy-slovenian',
        },
      ],
      ADMIN_MENU: [],
    },
  },
  UI: {
    SUBSCRIBE_BTN: 'Naročite se',
  },
  PAGES: {
    HOME: {
      TITLE: 'EPG',
    },
    NOT_FOUND: {
      TITLE: 'Oh, ne! Stran ni najdena',
      MESSAGE: 'Stran ni najdena',
    },
    SUBSCRIBE: {
      TITLE: 'Naročite se',
      NO_AFFIILIATES_MESSAGE:
        'There are no Partners registered for the selected Country.',
    },
    TERMS: {
      TITLE: 'Pogoji uporabe',
    },
    COMPETITION_RULES: {
      TITLE: '',
    },
    LEGAL_NOTICE: {
      TITLE: '',
    },
    TERMS_OF_SALE: {
      TITLE: '',
    },
  },
  SCHEDULE: {
    TITLE: 'Spored',
    MORNING: 'Jutro',
    AFTERNOON: 'Popoldne',
    EVENING: 'Večer',
    ON_AIR: 'Od Zdaj',
    NO_CONTENT: 'Na voljo ni nobena vsebina razporeda',
    SEASON: 'Sezona',
    EPISODE: 'Epizoda',
    DOWNLOAD_BTN_TEXT: 'Prenesite razpored',
  },
  DATE: {
    DAYS: [
      'Nedelja',
      'Ponedeljek',
      'Torek',
      'Sreda',
      'Četrtek',
      'Petek',
      'Sobota',
    ],
    MONTHS: [
      'Januar',
      'Februar',
      'Marec',
      'April',
      'Maj',
      'Junij',
      'Julij',
      'Avgust',
      'September',
      'Oktober',
      'November',
      'December',
    ],
  },
  COOKIE_BANNER: {
    UI: {
      POPUP_BACKGROUND: '#000000',
      POPUP_TEXT: '#ffffff',
      BUTTON_BACKGROUND: '#000000',
      BUTTON_TEXT: '#ffffff',
    },
    COPY: {
      MESSAGE:
        'Ta spletna stran uporablja piškotke, da vam zagotovi najboljšo izkušnjo na naši spletni strani.',
      DISMISS: 'V redu',
      LINK: 'Nauči se več',
      HREF: 'https://www.nbcuniversal.com/privacy/cookies',
    },
  },
  AFFILIATES: [
    {
      NAME: 'Zavod za CATV na Jami',
      LINK: 'http://www.komuniciraj.eu/ponudniki/zavod-ktv-na-jami',
      IMAGENAME: 'jami.jpg',
      SUBTITLE: null,
    },
    {
      NAME: 'Elcatel Slovenia',
      LINK: 'http://www.elcatel.si/',
      IMAGENAME: 'elcatel.jpg',
      SUBTITLE: null,
    },
    {
      NAME: 'Domcommerce',
      LINK: '#',
      IMAGENAME: 'placeholder.jpg',
      SUBTITLE: null,
    },
    {
      NAME: 'Naklo',
      LINK: 'http://www.naklo-logatec.si/',
      IMAGENAME: 'naklo.jpg',
      SUBTITLE: null,
    },
    {
      NAME: 'CATV Selnica Ruše',
      LINK: 'http://www.krs.net/',
      IMAGENAME: 'krs.jpg',
      SUBTITLE: null,
    },
    {
      NAME: 'Svislar Telekom',
      LINK: 'http://www.svislar-telekom.si/predstavitev',
      IMAGENAME: 'svislar.jpg',
      SUBTITLE: null,
    },
    {
      NAME: 'Telemach',
      LINK: 'http://www.telemach.si/',
      IMAGENAME: 'telemach.jpg',
      SUBTITLE: null,
    },
  ],
  TERMS: `<div class="copy__text">
    <p>
      Za vašo uporabo tega spletnega mesta veljajo pogoji storitve, ki so na voljo
      <a href="https://www.nbcuniversal.com/terms" target="_blank" rel="noopener noreferrer"
        >https://www.nbcuniversal.com/terms</a
      >, saj so kot taki dopolnjeni s temi pogoji uporabe.
    </p>
    <p>
      Za vašo uporabo tega spletnega mesta veljajo pogoji storitve, ki so na voljo
      https://www.nbcuniversal.com/terms, saj so kot taki dopolnjeni s temi pogoji
      uporabe. Obiskali ste spletno stran Universal Online, ki jo upravlja NBC
      Universal Global Networks Espana S.L.U. (“NBCU”). Vaša uporaba spletne
      strani (vključno z vsebinami in drugimi storitvami, ki jih občasno nudimo)
      je podvržena spodaj navedenim Splošnim pogojem, ki jih morate sprejeti ob
      vstopu in/ali registraciji na spletno stran:
    </p>
    <p>
      Ob registraciji na strani https://scifi.si zagotavljate, da se strinjate s
      prejemanjem naslednjih obvestil:
    </p>
    <p>
      - Če ste se odločili za prejemanje nadaljnjih podatkov o kanalu NBCU, vas
      bomo z e-pošto in sporočili SMS obveščali o podrobnostih drugih programov,
      dogodkov in nagradnih iger kanala NBCU.
    </p>
    <p>
      - Če ste se odločili za prejemanje nadaljnjih podatkov o trgovskih
      partnerjih kanala NBCU , vas bodo z e-pošto in sporočili SMS obveščali o
      podrobnostih drugih dogodkov, programov in nagradnih iger trgovskih
      partnerjev.
    </p>
    <p>
      - Prosimo, da več o naši uporabi osebnih podatkov preberete v načelu o
      Zasebnosti in varstvu podatkov, ki ga najdete v zadnjem delu teh splošnih
      pogojev. Prekinitev članstva na strani https://scifi.si
    </p>
    <p>
      - Prejemanje vsakršnih obvestil s strani https://scifi.silahko prekinete, če
      sledite povezavam, ki jih boste našli v e-sporočilih z naslovom Prekinitev
      članstva.
    </p>
    <p>Pravice do vsebin</p>
    <p>
      Avtorske in druge pravice do vsebin te spletne strani so v lasti kanala NBCU
      ali pa so vključeni v dovoljenje lastnika pravic. Kot obiskovalec te spletne
      strani, lahko prenesete eno samo kopijo vsebin, ki bo služila izključno
      ogledu v okviru vaše osebne rabe. Če vam ne posredujemo posebnega pisnega
      dovoljenja, ne smete razbirati, izvajati obratnega inženirstva,
      razstavljati, posojati, dajati v najem, posojati za protiplačilo, izdajati
      dovoljenja ali ustvariti izpeljanke spletne strani, ki vsebuje kakršen koli
      podatek ali programsko opremo, ki je na razpolago na tej spletni strani.
    </p>
    <p>
      Če v zadevnih Splošnih pogojih ni navedeno drugače, ne smete prepisovati,
      shranjevati, prenašati, spreminjati, posnemati, ponovno objaviti, prodajati,
      oddajati ali uporabljati v kakršen koli namen, bodisi posloven ali
      neposloven, spletno stran ali kakršen koli podatek, ki ga zajema spletna
      stran, razen, če je to nujno potrebno za branje, uporabo ali premikanje po
      spletni strani.
    </p>
    <p>Podatki, razpoložljivost in povezave z drugimi spletnimi stranmi</p>
    <p>
      NBCU prevzema vsakršno skrb za zagotovitev, da so podatki na zadevni spletni
      strani natančni in popolni, vendar nekatere podatke kanalu NBCU posredujejo
      druga podjetja in kanal NBCU ne more preveriti natančnosti ali popolnosti.
    </p>
    <p>
      Potrjujete in strinjate se, da nismo odgovorni za storitve in podatke, ki
      jih spletni strani posredujejo druga podjetja, ali za razpoložljivost katere
      koli druge spletne strani ali gradiva, do katerega imate dostop preko te
      spletne strani, in ne odobravamo in nismo zadolženi ali odgovorni za
      nikakršne vsebine, oglaševanje, poslovanje, izdelke ali storitve na ali na
      razpolago preko tovrstnih drugih podjetij, ali preko spletnih strani ali
      drugega gradiva, do katerega imate dostop preko te spletne strani, in za
      nikakršno škodo, izgubo ali napad, ki je povzročen ali naj bi bil povzročen
      z ali v povezavi z uporabo ali ob nanašanju na kakršne koli vsebine,
      oglaševanje, izdelke ali storitve, ki jih tovrstna druga podjetja nudijo na
      ali preko tovrstnih spletnih strani ali drugega gradiva.
    </p>
    <p>
      Vsakršen postopek med vami in drugimi podjetji, do katerih ste imeli dostop
      na ali preko spletne strani, vključno s plačili in dostavo izdelkov,
      storitev in kakršnih koli drugih določb, pogojev, jamstva ali predstavitve,
      ki se nanašajo na tovrstne postopke, ostanejo izključno med vami in zadevnim
      drugim podjetjem. Zavezujete se, da nas ne boste zadolžili za kakršno koli
      izgubo ali škodo, kateri bi se morebiti izpostavili zaradi kakršnega koli
      postopku te vrste, ali nas vpletli v kakršen koli spor med vami in drugim
      podjetjem.
    </p>
    <p>
      Ta spletna stran je na voljo „kot določeno", brez kakršnega koli jamstva, in
      NBCU ne sprejema nikakršne odgovornosti, ki bi jo povzročila nenatančnost
      ali opustitev podatkov, ki so na voljo na ali preko spletne strani ali bi
      sledila prekinitvi razpoložljivosti spletne strani.
    </p>
    <p>Začasna ukinitev spletne strani</p>
    <p>Pridržujemo si pravico:</p>
    <p>
      - zavrniti ali prekiniti vaš dostop do te spletne strani ali katerega koli
      dela spletne strani, z obvestilom ali brez, če menimo, da je vaša uporaba
      spletne strani žaljiva, prekomerna ali nasprotuje interesom drugih
      uporabnikov spletne strani ali krši te Pogoje za uporabo; ali
    </p>
    <p>
      - če je primerno, lahko vsakršne vsebine ali gradivo, ki ste ga naložili na
      spletno stran brez obvestila odstranimo, če menimo, da lahko nasprotuje tem
      Pogojem za uporabo.
    </p>
    <p>
      Poleg tega si pridržujemo pravico do začasne ukinitve, omejitve ali
      prekinitve dostopa do spletne strani iz kakršnega koli razloga in kadar
      koli.
    </p>
    <p>Omejitev odgovornosti</p>
    <p>
      V skladu z veljavnimi zakoni in izven morebitnega primera smrti ali telesnih
      poškodb, ki bi nastala zaradi naše malomarnosti, popolnoma izključujemo
      prevzem kakršne koli odgovornost za kakršne koli zahteve, izgube, prošnje in
      škodo, ki bi nastale posredno ali neposredno iz ali na kakršen koli način v
      povezavi s spletno stranjo in/ali katero koli storitev, ki jo občasno nudimo
      preko spletne strani. To izključevanje bo neomejeno veljalo v skladu s
      kakršno koli prekinitvijo storitve, izgube dobička, izgube možnosti za
      podpis pogodbe ali poslovanje, izgube podatkov ali kakršne koli druge
      posledične, postranske, posebne ali kazenske odškodnine, ki bi nastale
      zaradi spletne strani, tudi če smo poučeni o možnosti tovrstnih odškodnin,
      naj izvirajo iz pogodbe, kršitve, predpisa ali katerega koli drugega načina.
    </p>
    <p>Promocijske akcije in nagradne igre</p>
    <p>
      Občasno lahko NBCU (ali izbrano drugo podjetje) v vsebine zadevne spletne
      strani vključi nagradne igre, promocijske akcije ali druge ponudbe. Vsaka
      tovrstna ponudba bo podvržena zadevnim Posebnim pogojem in morda ne bo na
      voljo vsem pravosodnim pristojnostim.
    </p>
    <p>Vaše obveze</p>
    <p>
      Zavezujete se, da boste spletno stran uporabljali zgolj v skladu z zadevnimi
      Pogoji za uporabo in vsakršnimi dodatnimi pogoji za uporabo, ki bodo občasno
      veljavni, ter v skladu z vsemi veljavnimi zakoni in predpisi (, ki bo
      neomejeno vključeval zakone vaše države). Spletne strani (ali katerega koli
      dela strani) nikakor ne boste uporabljali za posredovanje ali pošiljanje
      kakršnega koli gradiva, ki je obrekljiv, žaljiv, opolzkega ali grozečega
      značaja, ali značaja, ki lahko po naši presoji komur koli povzroči
      nadlegovanje, obrekanje ali zaskrbljenost, vsebuje kakršen koli virus ali
      drugi običajni računalniški postopek, ki bi lahko poškodoval ali oviral
      delovanje spletne strani in/ali katerih koli storitev, ki jih nudi spletna
      stran.
    </p>
    <p>
      Zavezujete se, da spletne strani ne boste uporabljali na način, ki bi
      celotni spletni strani ali delu strani povzročil ustavitev, poškodbo,
      oslabitev učinkovitosti ali kakršno koli oškodovanje.
    </p>
    <p>
      V kolikor vaša uporaba ali uporaba katere koli osebe, ki ste jo pooblastili
      ali za katero ste odgovorni (na primer kot delodajalec) povzroči izgubo ali
      škodo nam ali kateri koli osebi, se zavezujete, da nas boste na zahtevo
      nemudoma zavarovali pred kaznijo v zvezi z vsakršnimi tovrstnimi izgubami
      ali škodami, ki so nam pripisane, vključujoč, kjer je to potrebno vsakršne
      pravne, upravne ali tehnične stroške, ki bi lahko izvirali iz tovrstne
      uporabe.
    </p>
    <p>Vaši prispevki</p>
    <p>
      Ko na spletno stran naložite gradivo (, ki brez omejevanja vsebuje kakršno
      koli besedilo, slikovno, video ali avdio gradivo) morate kanalu NBCU
      odobriti pravico in licence do neprekinjene, brezplačne, neizključne,
      podkoncesijske uporabe, posnemanja, spremembe, prilagajanja, objave,
      prevoda, izpeljanke, distribucije, uprizarjanja, igre in vaj vsakršne
      avtorske pravice in pravice do objave v skladu z vsakršnim tovrstnim delom
      kjer koli na svetu in/ali do vključevanja v druga dela v katerem koli
      mediju. Če tovrstne pravice kanalu NBCU ne želite odobriti, predlagamo, da
      svojih prispevkov ne nalagate na to spletno stran.
    </p>
    <p>Z nalaganjem prispevkov na spletno stran hkrati tudi:</p>
    <p>- jamčite, da so ti prispevki vaše izvirno delo;</p>
    <p>
      - se strinjate, da boste NBCU odškodovali za vsakršne pravne stroške, škodo
      ali druge stroške, katerim bo kanal NBCU izpostavljen zaradi vaše kršitve
      zgoraj navedenega jamstva; in
    </p>
    <p>
      - se strinjate, da se boste odrekli vsakršnim moralnim pravicam v vaših
      prispevkih za vse tu navedene namene.
    </p>
    <p>
      V primeru kakršnega koli nasprotovanja med temi Splošnimi pogoji in
      posebnimi pogoji, ki so navedeni na drugih mestih spletne strani in se
      nanašajo na določeno gradivo, bo slednje prevladalo.
    </p>
    <p>Splošno</p>
    <p>
      V namen nenehnega izboljševanja kakovosti spletne strani, si pridržujemo
      pravico občasno po lastni presoji spremeniti te Pogoje za uporabo in vaša
      odgovornost je, da te Pogoje o uporabi redno pregledujete.
    </p>
    <p>
      Če bi kateri koli od teh Splošnih pogojev postal nelegalen, razveljavljen
      ali bi povzroči neizvršljivost zaradi zakonov katere koli države, v kateri
      naj bi veljal, in bo zaradi tega tak Splošni pogoj nelegalen, razveljavljen
      ali bo povzročil neizvršljivost, bo prekinjen ali izbrisan, drugi splošni
      pogoji pa bodo ostali v celoti veljavni in bodo še vedno obvezujoči in se
      bodo izvrševali.
    </p>
    <p>Zakonska pristojnost</p>
    <p>
      Ti Pogoji za uporabo so pravno urejeni in se nanašajo izključno na zakone
      Anglije in Walesa, katerih sodišča bodo imela izključno pristojnost v
      kakršnem koli sporu, pod pogojem, da po lastni presoji začnemo in
      nadaljujemo pravni postopek pri drugih sodnih oblasteh
    </p>
  </div>
  `,
  COMPETITION_RULES: '',
  TERMS_OF_SALE: '',
  LEGAL_NOTICE: '',
};
