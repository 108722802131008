import PATHS from '../routes/paths';

export default {
  GLOBAL: {
    SITENAME: 'SCI FI',
    FOOTER: {
      COPYRIGHT_TEXT:
        '© {year} NBC Universal Global Networks España S.L.U.  All rights reserved.',
      NBC: 'Torre Europa, Paseo de la Castellana 95, Planta 10, 28046 Madrid, SPAIN RCS: B-82227893 MADRID',
      UTIL_MENU_TITLE: 'Przydatne linki',
      ADMIN_MENU_TITLE: 'Oferta NBCUniversal',
      CONTACT_TEXT: 'Contact us by e-mail: contact.SCIFIPL@nbcuni.com',
      COMMISSION_DETAILS:
        'NBC Universal Global Networks España S.L.U. is wholly owned by Universal Studios International BV',
      CNBC_COPY:
        'is subject to the Spanish jurisdiction and regulated in Spain by the National Commission on Competition & Markets (CNMC).',
      SOCIAL: [],
    },
    NAVIGATION: {
      MAIN: {
        HOME: 'Home',
      },
      SITE_MENU: [
        { title: 'Wybierz operatora', href: PATHS.subscribe },
        { title: 'Warunki użytkowania', href: PATHS.terms },
      ],
      UTIL_MENU: [
        {
          title: process.env.REACT_APP_AD_CHOICES ?? '',
          href: process.env.REACT_APP_AD_CHOICES_URL ?? '',
        },
        {
          title: 'Polityka Prywatności',
          href: 'https://www.nbcuniversal.com/privacy-policies/privacy-polish',
        },
      ],
      ADMIN_MENU: [
        { title: '13 ULICA', href: 'http://www.13ulica.pl/' },
        { title: 'E! ENTERTAINMENT', href: 'http://www.eonline.com/' },
      ],
    },
  },
  UI: {
    SUBSCRIBE_BTN: 'Sprawdź',
  },
  PAGES: {
    HOME: {
      TITLE: 'EPG',
    },
    NOT_FOUND: {
      TITLE: 'O nie! Strona nie znaleziona',
      MESSAGE: 'Strona nie znaleziona',
    },
    SUBSCRIBE: {
      TITLE: 'Wybierz operatora',
      NO_AFFIILIATES_MESSAGE:
        'There are no Partners registered for the selected Country.',
    },
    TERMS: {
      TITLE: 'Warunki użytkowania',
    },
    COMPETITION_RULES: {
      TITLE: '',
    },
    LEGAL_NOTICE: {
      TITLE: '',
    },
    TERMS_OF_SALE: {
      TITLE: '',
    },
  },
  SCHEDULE: {
    TITLE: 'Program',
    MORNING: 'Rano',
    AFTERNOON: 'Po południu',
    EVENING: 'Wieczorem',
    ON_AIR: 'NA ANTENIE',
    NO_CONTENT: 'Brak dostępnych treści harmonogramu',
    SEASON: 'Sezon',
    EPISODE: 'Odcinek',
    DOWNLOAD_BTN_TEXT: 'Pobierz ramówkę',
  },
  DATE: {
    DAYS: [
      'Niedziela',
      'Poniedziałek',
      'Wtorek',
      'Środa',
      'Czwartek',
      'Piątek',
      'Sobota',
    ],
    MONTHS: [
      'Styczeń',
      'Luty',
      'Marsz',
      'Kwiecień',
      'Może',
      'Czerwiec',
      'Lipiec',
      'Sierpień',
      'Wrzesień',
      'Październik',
      'Listopad',
      'Grudzień',
    ],
  },
  COOKIE_BANNER: {
    UI: {
      POPUP_BACKGROUND: '#000000',
      POPUP_TEXT: '#ffffff',
      BUTTON_BACKGROUND: '#ffffff',
      BUTTON_TEXT: '#000000',
    },
    COPY: {
      MESSAGE:
        'Ta strona korzysta z plików cookie, aby zapewnić Ci najlepszą jakość na naszej stronie.',
      DISMISS: 'Rozumiem',
      LINK: 'Dowiedz się więcej',
      HREF: 'https://www.nbcuniversal.com/privacy/cookies',
    },
  },
  AFFILIATES: [
    {
      NAME: 'Toya',
      SUBTITLE: 'Pakiet Extra kanał 123',
      LINK: 'http://toya.net.pl/',
      IMAGENAME: 'toya_2_0.jpg',
    },
    {
      NAME: 'Netia',
      SUBTITLE: 'Kanał 132 ',
      LINK: 'http://www.netia.pl/',
      IMAGENAME: 'logo_netia.jpg',
    },
    {
      NAME: 'CANAL+',
      SUBTITLE: 'Kanał: 107',
      LINK: 'https://pl.canalplus.com/',
      IMAGENAME: 'canalplus.jpg',
    },
    {
      NAME: 'Multimedia',
      SUBTITLE: 'Minibox, Superbox, Maxbox kanał 416',
      LINK: 'http://www.multimedia.pl/',
      IMAGENAME: 'nowe-logo-multimedia-polska.jpg',
    },
    {
      NAME: 'Polsat Box',
      SUBTITLE: 'Kanał: 51',
      LINK: 'http://www.cyfrowypolsat.pl/',
      IMAGENAME: 'Polsat_Box_small_scale_logo_horizontal.png',
    },
    {
      NAME: 'UPC',
      SUBTITLE: `kanał 253 UPC 4K BOX/Horizon, kanał 500 Mediabox`,
      LINK: 'https://www.upc.pl/telewizja/kup-telewizje/',
      IMAGENAME: 'UPC_CIRCLE_sRGB.png',
    },
    {
      NAME: 'WP Pilot',
      SUBTITLE: '',
      LINK: 'https://pilot.wp.pl/tv/',
      IMAGENAME: 'WP-Pilot_logo_color.png',
    },
  ],
  TERMS: `<div class="copy__text">
    <p>
      Korzystanie z niniejszej Witryny podlega Warunkom świadczenia usług
      dostępnym
      <a href="https://www.nbcuniversal.com/terms" target="_blank" rel="noopener noreferrer"
        >https://www.nbcuniversal.com/terms</a
      >, które są uzupełnione niniejszymi Warunkami użytkowania
    </p>
    <p>
      Jesteś na witrynie internetowej kanału Scifi Polska obsługiwanej przez firmę
      NBC Universal Global Networks Espana S.L.U. (“NBCU”) . Korzystanie przez
      Ciebie z niniejszej witryny (łącznie z jej zawartością i wszelkimi innymi
      usługami świadczonymi okresowo) podlega następującym Warunkom Użytkowania.
      Uznajemy, że wyrażasz na nie zgodę wchodząc na Witrynę i/lub rejestrując się
      na niej.
    </p>
    <p>
      Dokonując rejestracji na https://scifiuniversal.pl/ wyrażasz zgodę na
      otrzymywanie poniższych zawiadomień:
    </p>
    <p>
      - Jeśli zdecydowałeś się na otrzymywanie dodatkowych informacji na temat
      kanału Scifi Polska, skontaktujemy się z Tobą za pomocą poczty
      elektronicznej lub prześlemy wiadomości SMS ze szczegółowymi informacjami o
      innych programach, wydarzeniach i konkursach związanych z kanałem Sci Fi
      Polska.
    </p>
    <p>
      - Jeśli zdecydowałeś się na otrzymywanie dodatkowych informacji od
      komercyjnych partnerów firmy NBCU, skontaktują się oni z Tobą za pomocą
      poczty elektronicznej lub prześlą wiadomości SMS ze szczegółowymi
      informacjami o programach, wydarzeniach i konkursach zewnętrznego partnera
      komercyjnego.
    </p>
    <p>
      - Prosimy o zapoznanie się z naszymi Zasadami Prywatności, aby dowiedzieć
      się, jak postępujemy z Twoimi danymi osobowymi.
    </p>
    <p>Anulowanie subskrypcji https://scifiuniversal.pl/</p>
    <p>
      - Możesz zrezygnować z otrzymywania wszelkich zawiadomień z
      https://scifiuniversal.pl/, klikając na łącze „Anuluj subskrypcję” zawarte w
      każdej otrzymanej wiadomości email lub wysyłając pocztą elektroniczną prośbę
      o anulowanie subskrypcji na adres
      <a href="mailto:contact.SCIFIPL@nbcuni.com">contact.SCIFIPL@nbcuni.com.</a>
    </p>
    <p>Prawo do zawartości</p>
    <p>
      Prawa autorskie oraz wszelkie inne prawa do materiałów zamieszczonych na
      Witrynie należą do firmy NBCU lub materiały te zamieszczono za zgodą
      właściciela praw autorskich. Jako osoba odwiedzająca Witrynę możesz pobrać
      jedną kopię materiału, aby go samodzielnie obejrzeć. Bez naszego wyraźnego
      pisemnego pozwolenia nie masz prawa dokonywać dekompilacji, odwrotnej
      inżynierii, demontażu, wynajmowania, dzierżawienia, pożyczania,
      sublicencjonowania ani tworzenia produktów pochodnych na podstawie
      niniejszej Witryny, włączając w to wszelkie informacje i oprogramowanie
      udostępnione poprzez Witrynę. Jeśli Warunki Użytkowania nie przewidują
      inaczej, nie masz prawa kopiować, zapisywać, pobierać, modyfikować,
      reprodukować, ponownie publikować, rozpowszechniać ani wykorzystywać
      niniejszej Witryny oraz/lub zawartych na niej informacji w jakichkolwiek
      celach komercyjnych lub niekomercyjnych, w stopniu innym niż konieczny do
      wyświetlenia, korzystania i nawigowania po Witrynie.
    </p>
    <p>Informacje, dostępność i łącza z innymi witrynami</p>
    <p>
      Mimo, że firma NBCU dokłada wszelkich starań, aby informacje na Witrynie
      były pełne i dokładne, część informacji pochodzi od dostawców zewnętrznych.
      Firma NBCU nie ponosi odpowiedzialności za: usługi lub informacje
      zamieszczone na Witrynie pochodzące od dostawców zewnętrznych; za dostępność
      innych Witryn; za jakiekolwiek materiały, reklamy, transakcje, produkty lub
      usługi dostępne na Witrynie lub udostępniane przez dostawców zewnętrznych
      lub przez witryny lub inne materiały, do których docierasz za pośrednictwem
      Witryny; za żadne szkody, straty lub łamanie prawa domniemane lub
      spowodowane przez lub w związku z wykorzystywaniem lub bazowaniem na
      materiałach, reklamie, produktach lub usługach udostępnianych przez stronę
      zewnętrzną lub dostępnych poprzez witryny zewnętrzne lub inne materiały.
      Wszelkie kontakty pomiędzy Tobą i jakąkolwiek stroną trzecią, z którą
      nawiązałeś kontakt na Witrynie lub poprzez Witrynę, włączając w to płatności
      za produkty i dostawy produktów, usług oraz wszelkie inne warunki,
      zastrzeżenia, gwarancje lub zażalenia wynikające z tych kontaktów, są
      wyłącznie sprawą między Tobą i daną stroną trzecią. Przyjmując niniejsze
      Warunki Użytkowania, zgadzasz się, aby nie obarczać nas odpowiedzialnością
      za jakiekolwiek straty lub szkody powstałe w wyniku tych kontaktów i nie
      angażować nas w spory między Tobą a stroną trzecią. Firma NBCU nie ponosi
      żadnej odpowiedzialności wynikającej z nieścisłości lub pominięcia
      informacji dostępnych na Witrynie lub poprzez nią, nie ponosi również
      odpowiedzialności za przerwę w działaniu lub dostępie do Witryny.
    </p>
    <p>Zawieszenie działania Witryny</p>
    <p>Zastrzegamy sobie prawo do:</p>
    <p>
      - odmowy lub zawieszenia Twojego dostępu do Witryny lub dowolnego działu
      Witryny za uprzednim powiadomieniem lub bez uprzedniego powiadomienia, jeśli
      korzystasz z Witryny lub jej części w sposób, który uznamy za obraźliwy,
      przesadny, niezgodny z interesem innych użytkowników Witryny lub naruszający
      niniejsze WarunkiUżytkowania;
    </p>
    <p>
      - usunięcie, w stosownych przypadkach, z uprzednim powiadomieniem lub bez
      takiego powiadomienia, wszelkich materiałów lub materiału przekazanego na
      Witrynę, jeśli będziemy zdania, że może on być sprzeczny z niniejszymi
      Warunkami Użytkowania. Ponadto zastrzegamy sobie prawo zawieszenia,
      ograniczenia lub zawieszenia dostępu do niniejszej Witryny z dowolnego
      powodu w dowolnym czasie.
    </p>
    <p>Ograniczenie odpowiedzialności</p>
    <p>
      W najwyższym dopuszczalnym przez prawo stopniu, za wyjątkiem spowodowania
      śmierci lub obrażeń ciała wskutek naszych zaniedbań, wykluczamy
      odpowiedzialność za wszelkie roszczenia, straty, żądania i szkody powstałe
      bezpośrednio lub pośrednio z powodu Witryny lub w jakikolwiek sposób
      powiązane z nią oraz/lub z jakimikolwiek usługami oferowanymi okresowo przez
      Witrynę. Wykluczenie to stosuje się bez ograniczeń do przerw w świadczeniu
      usługi, utraty zysków, utraty kontraktów lub sposobności biznesowych, utraty
      danych lub jakichkolwiek innych szkód następczych, przypadkowych,
      specjalnych lub moralnych wynikających z użytkowania Witryny, nawet jeśli
      powiadomiono nas o możliwości powstania takich szkód, wynikających z
      kontraktu, deliktu, obowiązującego prawa lub z innego powodu.
    </p>
    <p>Promocje i konkursy</p>
    <p>
      Firma NBCU (lub wybrana strona trzecia, za zgodą firmy) może na Witrynie
      ogłaszać konkursy, promocje lub inne oferty. Każda taka oferta będzie
      podlegała określonym, jasno sprecyzowanym warunkom i może nie być dostępna
      we wszystkich jurysdykcjach.
    </p>
    <p>Twoje zobowiązania</p>
    <p>
      Wyrażasz zgodę na korzystanie z Witryny wyłącznie w sposób zgodny z
      niniejszymi Warunkami Użytkowania i wszelkimi dodatkowymi warunkami w sposób
      zapewniający przestrzeganie wszystkich stosownych praw i zarządzeń (w tym
      także, bez ograniczenia, przestrzeganie prawa miejscowego). W szczególności
      nie będziesz korzystać z Witryny (ani z żadnej jej części) do przekazywania
      lub umieszczania jakichkolwiek materiałów o charakterze zniesławiającym,
      obraźliwym, nieprzyzwoitym lub zastraszającym lub materiałów, które naszym
      zdaniem mogą spowodować irytację, niedogodność, niepokój, zawierają wirus
      lub inną zaprogramowaną procedurę, która może spowodować uszkodzenie lub
      zakłócenia w działaniu Witryny oraz/lub usług świadczonych przez Witrynę.
      Zgadzasz się nie korzystać z Witryny w sposób, który mógłby spowodować
      przerwanie dostępu do całej Witryny lub jej części, uszkodzenie Witryny lub
      jej części, zmniejszenie efektywności lub upośledzenie jej w jakikolwiek
      sposób. W zakresie, w którym użytkowanie przez Ciebie lub upoważnioną przez
      Ciebie osobę, za którą ponosisz odpowiedzialność (np. jako pracodawca)
      wyrządzi nam lub komukolwiek innemu stratę lub szkodę, zgadzasz się
      natychmiast na żądanie wynagrodzić nam wszelkie straty lub szkody nam
      przypisywane, w razie potrzeby, łącznie z wszelkimi opłatami sądowymi,
      administracyjnymi lub technicznymi, które mogą wyniknąć z takiego
      korzystania.
    </p>
    <p>Twój wkład</p>
    <p>
      Kiedy przekazujesz jakiekolwiek materiały na Witrynę (łącznie z wszelkimi
      tekstami, materiałami graficznymi, wideo lub dźwiękowymi), przekazujesz
      firmie NBCU wieczyste, bez honorarium, niewyłączne, podlegające sublicencji
      prawo oraz licencję na użytkowanie, reprodukcję, modyfikację, adaptację,
      publikację, tłumaczenie, tworzenie produktów pochodnych, rozpowszechnianie,
      wystawianie, odtwarzanie i korzystanie z wszelkich praw autorskich oraz praw
      do reklamy każdego z tych materiałów na całym świecie i/lub włączenia ich do
      innych prac lub mediów. Jeśli nie chcesz przekazać firmie NBCU tych praw,
      sugerujemy nie umieszczać swojej pracy na Witrynie.
    </p>
    <p>Ponadto, umieszczając swoją pracę na Witrynie:</p>
    <p>- gwarantujesz, że jest ona Twoją oryginalną pracą;</p>
    <p>
      - zgadzasz się na pokrycie kosztów sądowych, odszkodowania i innych
      wydatków, które NBCU może ponieść w wyniku naruszenia przez Ciebie powyższej
      gwarancji;
    </p>
    <p>
      - zrzekasz się wszelkich moralnych praw do swojej pracy w stosunku do
      wszystkich wymienionych tu zastosowań.
    </p>
    <p>
      W przypadku jakiegokolwiek konfliktu między niniejszymi Warunkami i
      Zastrzeżeniami a określonymi warunkami opublikowanymi w innej części
      Witryny, odnoszącymi się do określonego materiału, obowiązują te ostatnie.
    </p>
    <p>Ustalenia ogólne</p>
    <p>
      W celu stałego doskonalenia Witryny zastrzegamy sobie prawo do wprowadzania
      zmian do niniejszych Warunków Użytkowania wyłącznie według naszego uznania.
      Twoim obowiązkiem jest regularne sprawdzanie niniejszych Warunków
      Użytkowania. Jeśli zostanie stwierdzone, że dowolna część niniejszych
      Warunków i Zastrzeżeń jest niezgodna z prawem, nieważna lub nie można jej
      wprowadzić w życie ze względu na prawa obowiązujące w stanie lub w kraju, w
      którym takie Warunki i Zastrzeżenia miałyby obowiązywać, wówczas w zakresie,
      w którym są one nielegalne, nieważne lub nie da się ich wprowadzić w życie,
      zostaną one oddzielone i usunięte, a pozostałe Warunki i Zastrzeżenia
      pozostają w pełnej w mocy.
    </p>
    <p>Obowiązujące prawo</p>
    <p>
      Niniejsze Warunki Użytkowania podlegają wyłącznie prawu Anglii i Walii i
      będą interpretowane wyłącznie zgodnie z tym prawem. Sądy Anglii i Walii
      sprawują wyłączną jurysdykcję we wszelkich sporach, z tym zastrzeżeniem, że,
      według naszego uznania, przysługuje nam prawo do wszczęcia i przeprowadzenia
      postępowania sądowego w innej jurysdykcji.
    </p>
  </div>
  `,
  COMPETITION_RULES: '',
  TERMS_OF_SALE: '',
  LEGAL_NOTICE: '',
};
