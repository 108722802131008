import React from 'react';
import cx from 'classnames';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop';
import PATHS from './routes';

/** PAGES. */
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Schedule from './pages/Schedule';
import Terms from './pages/Terms';
import Competition from './pages/Competition';
import Legal from './pages/Legal';
import Sale from './pages/Sale';
import Subscribe from './pages/Subscribe';
import RedirectToHome from './pages/RedirectToHome';
import Cookies from './pages/Cookies';

const App = (): React.ReactNode => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <div className={cx('App', 'root')}>
          <div className="root-content">
            <Routes>
              <Route path={PATHS.home} element={<Home />} />
              <Route path={PATHS.schedule} element={<Schedule />} />
              <Route path={PATHS.terms} element={<Terms />} />
              <Route path={PATHS.subscribe} element={<Subscribe />} />
              <Route path={PATHS.oldschedule} element={<RedirectToHome />} />
              <Route path={PATHS.competition} element={<Competition />} />
              <Route path={PATHS.termsSale} element={<Sale />} />
              <Route path={PATHS.legal} element={<Legal />} />
              <Route path={PATHS.cookies} element={<Cookies />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default App;
