import ROUTE_PATHS from '../routes';

/**
 * Paths.
 */
export const PATHS = ROUTE_PATHS;

/**
 * API Endpoints.
 */
export const API_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

/**
 * App config.
 */
const { REACT_APP_CDN_URL, REACT_APP_EPGNAME, REACT_APP_LOCALE } = process.env;

export const CHARACTERS_ONLY_REGEX =
  /^[a-zA-ZÀ-úÀ-ÿ0-9_'-]+( [a-zA-Z0-9_]+)*$/g;
export const NO_SCROLL_CLASS = 'no-scroll';
export const AWS_ENDPOINT = REACT_APP_CDN_URL;
export const EPG_REGION = REACT_APP_EPGNAME;
export const LOCALE = REACT_APP_LOCALE;

export const S3_STATIC_ASSETS_URL =
  'https://d16grqjkf2kebt.cloudfront.net/static-images';
