import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { urlFormatDate } from '../../tools/dateFuncs';

const Home = (): React.ReactElement | null => {
  const navigate = useNavigate();
  const date = new Date();
  const formattedDate = urlFormatDate(date.toString());

  React.useEffect(() => {
    navigate(`/schedule/${formattedDate}`);
  }, []);

  if (Number(date) < 1) {
    return null;
  }

  return <div />;
};

export default Home;
