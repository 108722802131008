import en from './en';
import street13thpl from './13thstreetpl';
import street13thfr from './13thstreetfr';
import divaromania from './divaromania';
import movies24 from './movies24';
import scifipl from './scifipl';
import scifirs from './scifirs';
import scifisi from './scifisi';
import studioza from './studioza';
import syfyuk from './syfyuk';
import syfyfr from './syfyfr';
import universalau from './universalau';
import universalza from './universalza';
import dreamworkses from './dreamworkses';
import dreamworksnl from './dreamworksnl';

const { REACT_APP_LOCALE } = process.env;

const setLang = (lang: string | undefined) => {
  switch (lang) {
    case '13thstreetpl':
      return street13thpl;
    case '13thstreetfr':
      return street13thfr;
    case 'divaromania':
      return divaromania;
    case 'dreamworkses':
      return dreamworkses;
    case 'dreamworksnl':
      return dreamworksnl;
    case 'movies24':
      return movies24;
    case 'scifipl':
      return scifipl;
    case 'scifirs':
      return scifirs;
    case 'scifisi':
      return scifisi;
    case 'studioza':
      return studioza;
    case 'syfyuk':
      return syfyuk;
    case 'syfyfr':
      return syfyfr;
    case 'universalau':
      return universalau;
    case 'universalza':
      return universalza;
    default:
      return en;
  }
};

const strings = setLang(REACT_APP_LOCALE);

export default strings;
