import { scheduleKey as KEY } from '../../schemas/config';
import { State } from './schedule';

/**
 * Get all schedule items from state.
 *
 * @param {object} state
 * @param {string} date
 *
 * @returns {(Array|null)}
 */
const selectScheduleItems = (state: State, date: string | Date) => {
  if (!state[KEY]?.entities) return null;

  const entries = Object.values(state[KEY].entities);
  const formattedDate = String(date).replace(/-/g, '/');
  return entries.filter(entry => {
    return entry.date === formattedDate;
  });
};

export default selectScheduleItems;
