import PATHS from '../routes/paths';

export default {
  GLOBAL: {
    SITENAME: 'Diva România',
    FOOTER: {
      COPYRIGHT_TEXT:
        '© {year}  NBC Universal Global Networks España S.L.U.  All rights reserved.',
      NBC: 'Torre Europa, Paseo de la Castellana 95, Planta 10, 28046 Madrid, SPAIN RCS: B-82227893 MADRID',
      UTIL_MENU_TITLE: 'Link-uri utile',
      ADMIN_MENU_TITLE: 'O divizie a NBCUniversal',
      CONTACT_TEXT: 'Contact us by e-mail: contact.DIVARO@nbcuni.com',
      COMMISSION_DETAILS:
        'NBC Universal Global Networks España S.L.U. is wholly owned by Universal Studios International BV',
      CNBC_COPY:
        'is subject to the Spanish jurisdiction and regulated by the National Commission on Competition & Markets (CNMC).',
      SOCIAL: [],
    },
    NAVIGATION: {
      MAIN: {
        HOME: 'Home',
      },
      SITE_MENU: [
        { title: 'Abonati-va', href: PATHS.subscribe },
        { title: 'Termeni si conditii', href: PATHS.terms },
      ],
      UTIL_MENU: [
        {
          title: process.env.REACT_APP_AD_CHOICES ?? '',
          href: process.env.REACT_APP_AD_CHOICES_URL ?? '',
        },
        {
          title: 'Politica privind confidențialitatea',
          href: 'https://www.nbcuniversal.com/privacy-policies/privacy-romanian',
        },
      ],
      ADMIN_MENU: [
        {
          title: 'NBC Universal',
          href: 'http://www.nbcuniversal.com/',
        },
      ],
    },
  },
  UI: {
    SUBSCRIBE_BTN: 'Abonati-va',
  },
  PAGES: {
    HOME: {
      TITLE: 'EPG',
    },
    NOT_FOUND: {
      TITLE: 'Oh nu! Pagina nu a fost gasita',
      MESSAGE: 'Pagina nu a fost gasita',
    },
    SUBSCRIBE: {
      TITLE: 'Abonati-va',
      NO_AFFIILIATES_MESSAGE:
        'Nu există niciun partener înregistrat pentru țara selectată.',
    },
    TERMS: {
      TITLE: 'Termeni si conditii',
    },
    COMPETITION_RULES: {
      TITLE: '',
    },
    LEGAL_NOTICE: {
      TITLE: '',
    },
    TERMS_OF_SALE: {
      TITLE: '',
    },
  },
  SCHEDULE: {
    TITLE: 'Grila de programe',
    MORNING: 'Dimineața',
    AFTERNOON: 'După-amiaza',
    EVENING: 'Seara',
    ON_AIR: 'Now on',
    NO_CONTENT: 'Nu există conținut disponibil în program',
    SEASON: 'Sezon',
    EPISODE: 'Episod',
    DOWNLOAD_BTN_TEXT: 'Descărcați programul',
  },
  DATE: {
    DAYS: ['Duminică', 'Luni', 'Marţi', 'Miercuri', 'Joi', 'Vineri', 'Sâmbătă'],
    MONTHS: [
      'Ianuarie',
      'Februarie',
      'Martie',
      'Aprilie',
      'Mai',
      'Iunie',
      'Iulie',
      'August',
      'Septembrie',
      'Octombrie',
      'Noiembrie',
      'Decembrie',
    ],
  },
  COOKIE_BANNER: {
    UI: {
      POPUP_BACKGROUND: '#000000',
      POPUP_TEXT: '#ffffff',
      BUTTON_BACKGROUND: '#000000',
      BUTTON_TEXT: '#ffffff',
    },
    COPY: {
      MESSAGE:
        'Acest site folosește cookie-uri pentru a vă asigura că beneficiați de cea mai bună experiență pe site-ul nostru.',
      DISMISS: 'O.K',
      LINK: 'Aflați mai multe',
      HREF: 'https://www.nbcuniversal.com/privacy/cookies',
    },
  },
  AFFILIATES: null,
  TERMS: `<div class="copy__text">
    <p>
      Your use of this Website is subject to the Terms of Service available
      <a href="https://www.nbcuniversal.com/terms" target="_blank" rel="noopener noreferrer">here</a>
      as such are supplemented by these Terms and Conditions.
    </p>
    <p>
      The conditions and terms of use of the page
      <a href="http://www.divaromania.tv/">http://www.divaromania.tv/</a>. The
      page you have accessed is DIVA Romania (“Website”) , a page operated by NBC
      Universal Global Networks Espana S.L.U. (“NBCU”). Your use of this website
      (including its content and other services provided by us from time to time)
      depends on the following Terms of Use, which you must accept by accessing
      and/or registering on our website: By registering on the
      http://www.divaromania.tv/ page you agree to receive the following
      information:
    </p>
    <p>
      1. If you have opted to receive additional details about DIVA Romania, you
      will be contacted by e-mail and/or SMS for details of other programs, events
      and contests about DIVA Romania.
    </p>
    <p>
      2. If you have opted to receive additional details from DIVA Romania’s
      commercial partners, you will be contacted by e-mail and/or SMS to receive
      details of events, programs and competitions of the commercial partner/third
      party.
    </p>
    <p>
      3. Please read our Privacy Policy for details on how we treat your personal
      information. Suspension of subscription to http://www.divaromania.tv/ . You
      can suspend all media subscriptions on the http://www.divaromania.tv/ page
      by clicking on the links to suspend subscriptions to e-mails or by sending a
      request to suspend subscriptions to
      <a href="mailto:contact.DIVARO@nbcuni.com">contact.DIVARO@nbcuni.com</a>
      . Content rights Copyright and related rights on this Internet page are the
      property of NBCU or are included in the permission provided by the copyright
      owner. Unless we give you express written permission, you may not extract,
      reorganize, deconstruct, rent, loan, sublicense or create derivative works
      from the content of this Site, which includes all information and computer
      programs available on the Site. Unless otherwise stated in these Terms and
      Conditions of Use, you may not copy, save, download, modify, reproduce,
      re-publish, distribute, transmit or use for any other purpose, commercial or
      non-commercial, the Website or other information on this Website, except for
      the content necessary to view and browse the Website. The information,
      availability and links to other pages - DIVA Romania strives to ensure the
      completeness and accuracy of the information on this website, but some
      information is provided by third parties and DIVA Romania cannot verify its
      completeness and accuracy. We understand that you acknowledge and accept
      that we are not responsible for the services or information offered by third
      parties on our Site or for the availability of any pages or materials you
      access through our Site, and that we are not responsible for or have any
      obligations regarding the content, advertising, transactions, products or
      services offered or available from such third parties or from the internet
      pages; or relating to other materials you access through our website, nor
      shall we be liable for any loss, damage or offence caused or alleged to be
      caused, by or in connection with the use of or reliance on any content,
      advertising, products or services available from such third parties or
      available from such websites or other materials. Any relationships between
      you and any third party accessed through our website, including payment and
      delivery of products, services and any other conditions, terms, warranties
      or representations associated with these relationships are relevant only to
      you and the third party. We understand that you agree not to hold us
      responsible for any loss or damage of any kind arising out of such
      relationships or to involve us in disputes between you and third parties.
      This website is provided "as is"; without warranties of any kind, and NBCU
      accepts no liability arising from the inaccuracy or omission of information
      available on or through the Site or the termination of the availability of
      this Site. Suspension of the Site We reserve the right to: (i) prohibit or
      suspend your access to the Site or part of the Site with or without notice,
      if we believe that you are using the Site abusively, excessively or against
      the interests of other users of the Site or in violation of these Terms of
      Use; or (ii) if applicable, remove any content or material placed on the
      Site by You with or without notice if We believe it contravenes the Terms of
      Use. In addition, we reserve the right to suspend, restrict or terminate
      access to the Site for any reason and at any time.
    </p>
    <p>
      <b>Limits of liability</b> We exclude, to the extent permitted by applicable
      laws, and reserve liability for any claims, losses, demands or damages, for
      fatalities or personal damage arising from our negligence, and caused
      directly or indirectly by or in connection with the Site and/or the services
      offered by the Site from time to time. Exceptions will be made without
      limitation to suspension of services, lost profits, loss of contracts or
      business opportunities, loss of data or other consequential, incidental,
      special or punitive damages caused by the Website, even if we are advised of
      the possibility of such damages, arising in contract, in connection with
      statute or otherwise. Periodical, DIVA Romania (or selected third parties)
      promotions and contests may include contests, promotions, or other offers on
      this Site. Each such offer shall be subject to its own terms and conditions
      and may not be valid in all jurisdictions.
    </p>
    <p>
      <b>Your obligations to us</b> We understand that You agree to use this Site
      in compliance with these Terms of Use and any additional terms that will be
      effective from time to time, thereby ensuring compliance with all applicable
      laws and regulations (including, without limitation, local laws in Your
      jurisdiction). In particular, you will not use the Website (or any part
      thereof) to transmit or display material that is defamatory, offensive or
      obscene or threatening, or which could, in our opinion, cause annoyance,
      inconvenience or anxiety to any person or who contains viruses or other
      routine procedures in computer programming and which could interfere with
      the operation of the website and/or in the provision of services offered by
      the website. We understand that you agree not to use the Site in such a way
      as to cause the suspension of all or part of it, prejudice or reduction of
      the effectiveness of the Site in any way. To the extent that loss or damage
      is caused by the use by you or another person who may be authorized by you
      or for whom you are responsible (for example, as an employer), damage caused
      to us or to another person, you agree to pay us compensation immediately,
      upon demand, in connection with such loss or damage attributable to us,
      including any administrative, legal or technical charges that may arise in
      such cases.
    </p>
    <p>
      <b>Your Contributions</b> Whenever you provide material on the Site
      (including without limitation text, graphics, video or audio materials) you
      grant NBCU a perpetual, royalty-free, non-exclusive right, the ability to
      sub-license and license the use, reproduction, modification, adaptation,
      publication, translation and creation of derivative works, distribution,
      public performance and exercise of copyright and publicity in such works, at
      international level and/or incorporate them into other media. If you do not
      wish to grant these rights to NBCU, we suggest that you do not provide
      contributions to the Website. By submitting contributions on the website,
      you also:
    </p>
    <p>1. Warrant that the submitted works belong to you and are original;</p>
    <p>
      2. You agree to pay compensation for legal fees, damages and other expenses
      to be incurred by NBCU as a result of Your breach of the above-mentioned
      warranty; and
    </p>
    <p>
      3. You agree to waive moral rights over Your contribution for the purposes
      stated above. If there are any conflicts between these Terms of Use and the
      specific terms appearing on this website relating to specific materials, the
      latter shall prevail.
    </p>
    <p>
      <b>General provisions</b> In order to continuously improve the quality of
      the Website, we reserve the right to periodically make changes to the Terms
      of Use at our sole discretion and it is your responsibility to periodically
      review these Terms of Use. If any of these Terms of Use shall be held
      illegal, invalid or unenforceable by law in any state or country in which
      they are presumed to be valid, then to the extent that these Terms of Use
      are illegal, invalid or unenforceable, they shall be excluded and the
      remaining Terms of Use shall remain in full force and effect.
    </p>
    <p>
      <b>Governing Law</b> These Terms of Use are governed solely by the laws of
      England and Wales, whose courts shall have exclusive jurisdiction in any
      dispute, except to the extent that we are entitled in our sole discretion to
      bring legal proceedings in other jurisdictions.
    </p>
  </div>
  `,
  COMPETITION_RULES: '',
  TERMS_OF_SALE: '',
  LEGAL_NOTICE: '',
};
