import React from 'react';
import DayPicker from 'react-day-picker';
import { useNavigate } from 'react-router-dom';
import styles from './NavDatePicker.module.scss';
import { urlFormatDate } from '../../tools/dateFuncs';
import { LOCALE } from '../../constants';
import strings from '../../translations';

const { MONTHS } = strings.DATE;

type Props = {
  callBack: (a: null, b: string) => void;
  hide: () => void;
};

const NavDatePicker = ({ callBack, hide }: Props): React.ReactElement => {
  const navigate = useNavigate();
  const handleDayClick = (day: Date) => {
    const formattedDate = urlFormatDate(String(day));
    const url = `/schedule/${formattedDate}`;
    navigate(url);
    callBack(null, formattedDate);
    hide();
  };

  const Year = new Date().getFullYear();
  const fromMonth = new Date().getMonth() - 1;
  const toMonth = new Date().getMonth() + 1;

  return (
    <div className={styles.layout}>
      <DayPicker
        onDayClick={handleDayClick}
        fromMonth={new Date(Year, fromMonth)}
        toMonth={new Date(Year, toMonth)}
        locale={LOCALE}
        months={MONTHS}
      />
    </div>
  );
};

export default NavDatePicker;
