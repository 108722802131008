import PATHS from '../routes/paths';

export default {
  GLOBAL: {
    SITENAME: 'Scifi Србија',
    FOOTER: {
      COPYRIGHT_TEXT:
        '© {year} NBC Universal Global Networks España S.L.U.  All rights reserved.',
      NBC: 'Torre Europa, Paseo de la Castellana 95, Planta 10, 28046 Madrid, SPAIN RCS: B-82227893 MADRID',
      UTIL_MENU_TITLE: 'Korisni linkovi',
      ADMIN_MENU_TITLE: 'Divizija NBCUniversal',
      CONTACT_TEXT: 'Contact us by e-mail: contact.SCIFIRS@nbcuni.com',
      COMMISSION_DETAILS:
        'NBC Universal Global Networks España S.L.U. is wholly owned by Universal Studios International BV',
      CNBC_COPY:
        'is subject to the Spanish jurisdiction and regulated by the National Commission on Competition & Markets (CNMC).',
      SOCIAL: [],
    },
    NAVIGATION: {
      MAIN: {
        HOME: 'Home',
      },
      SITE_MENU: [
        { title: 'Pretplatiti se', href: PATHS.subscribe },
        { title: 'Uslovi korišćenja', href: PATHS.terms },
      ],
      UTIL_MENU: [
        {
          title: process.env.REACT_APP_AD_CHOICES ?? '',
          href: process.env.REACT_APP_AD_CHOICES_URL ?? '',
        },
        {
          title: 'Pravila o privatnosti',
          href: 'https://www.nbcuniversal.com/privacy-policies/privacy-serbian',
        },
      ],
      ADMIN_MENU: [],
    },
  },
  UI: {
    SUBSCRIBE_BTN: 'pretplatiti se',
  },
  PAGES: {
    HOME: {
      TITLE: 'EPG',
    },
    NOT_FOUND: {
      TITLE: 'O, ne! Stranica nije pronađena',
      MESSAGE: 'Stranica nije pronađena',
    },
    SUBSCRIBE: {
      TITLE: 'Pretplatiti se',
      NO_AFFIILIATES_MESSAGE:
        'There are no Partners registered for the selected Country.',
    },
    TERMS: {
      TITLE: 'Uslovi korišćenja',
    },
    COMPETITION_RULES: {
      TITLE: '',
    },
    LEGAL_NOTICE: {
      TITLE: '',
    },
    TERMS_OF_SALE: {
      TITLE: '',
    },
  },
  SCHEDULE: {
    TITLE: 'Raspored',
    MORNING: 'Jutro',
    AFTERNOON: 'Popodne',
    EVENING: 'Veče',
    ON_AIR: 'Sada',
    NO_CONTENT: 'Nije dostupan sadržaj rasporeda.',
    SEASON: 'Sezona',
    EPISODE: 'Epizoda',
    DOWNLOAD_BTN_TEXT: 'Preuzmite Raspored',
  },
  DATE: {
    DAYS: [
      'Nedelja',
      'Ponedeljak',
      'Utorak',
      'Sreda',
      'Četvrtak',
      'Petak',
      'Subota',
    ],
    MONTHS: [
      'Januar',
      'Februar',
      'March',
      'April',
      'Može',
      'June',
      'Jul',
      'August',
      'Septembar',
      'October',
      'Novembar',
      'Decembar',
    ],
  },
  COOKIE_BANNER: {
    UI: {
      POPUP_BACKGROUND: '#000000',
      POPUP_TEXT: '#ffffff',
      BUTTON_BACKGROUND: '#000000',
      BUTTON_TEXT: '#ffffff',
    },
    COPY: {
      MESSAGE:
        'Ovaj sajt koristi kolačiće kako bi vam obezbedio najbolje iskustvo na našem sajtu.',
      DISMISS: 'U redu',
      LINK: 'Saznajte više',
      HREF: 'https://www.nbcuniversal.com/privacy/cookies',
    },
  },
  AFFILIATES: [
    {
      NAME: 'Elta d.o.o',
      LINK: 'http://www.elta-kabel.com/',
      IMAGENAME: 'elta_logo.jpg',
      SUBTITLE: null,
    },
    {
      NAME: 'Exe Net Advertising',
      LINK: 'http://www.exe-net.net/index.php',
      IMAGENAME: 'exnet.jpg',
      SUBTITLE: null,
    },
    {
      NAME: 'SBB',
      LINK: 'http://store.virginmedia.com/index.html?id=1',
      IMAGENAME: 'sbb_logo_1.jpg',
      SUBTITLE: null,
    },
  ],
  TERMS: `<div class="copy__text">
    <p>1. Uvod</p>
    <p>
      Vaša upotreba ovog Veb sajta podleže Uslovima korišćenja usluga koji su
      <a href="https://www.nbcuniversal.com/terms" target="_blank" rel="noopener noreferrer"
        >https://www.nbcuniversal.com/terms</a
      >, jer su takvi dopunjeni ovim Uslovima
      korišćenja
    </p>
    <p>
      Ovi Uslovi, sa svim svojim amandmanima, važe za sve korisnike Sci Fi
      (Srbija) (u daljem tekstu „Site“), kojom rukovodi „ NBC Universal Global
      Networks España S.L.U. “, (u daljem tekstu „Kompanija“ ili „mi“) Mi vam
      nudimo Site, uključujuči i podatke od kojih se Site sastoji i koje generiše,
      ukoliko prihvatite: Ovde navedene Uslove; i Politiku privatnosti koja se
      nalazi na https://www.nbcuniversal.com/privacy-policies/privacy-serbian (u
      daljem tekstu „Politika privatnosti“). Vaše korišćenje Site je podložno svim
      važećim zakonima i propisima, svim uslovima kojima je regulisan. Mi
      zadržavamo pravo da ove Uslove povremeno promenimo po sopstvenom nahođenju.
      Vaša odgovornost je da redovno ove Uslove proveravate. Izmenjena verzija
      Uslova će biti ovde dostupna. Nastavljanjem korišćenja Site saglasni ste sa
      izmenjenim, trenutno važećim uslovima.
    </p>
    <p>
      2. Privatnost Mi poštujemo vašu privatnost. Za detaljne informacije,
      uključujući i informacije o tome kako i u kojim okolnostima imamo pravo da
      prikupljamo, koristimo i otkrivamo lične podatke korisnika Site, pogledajte
      Politiku privatnosti.
    </p>
    <p>
      3. Korišćenje Aplikacije Možete pristupiti i koristiti Site isključivo u
      skladu sa ovde navedenim uslovima. Pristupanje ili korišćenje Site i njenih
      sadržaja (uključujući, ali ne ograničavajući se na, slike, tekst, podatke,
      elemente dizajna, grafiku, logo, kao i njihov izbor i razmeštaj) na svaki
      drugi način su izričito zabranjeni.
    </p>
    <p>
      Kao korisnik Site, sadržaju koji se nalazi u Site ili koji Site generiše
      možete pristupiti i koristiti ga samo za nekomercijalne i lične potrebe osim
      ukoliko smo vam mi dali izričito prethodno pisano odobrenje. Nećete
      koristiti Site da šaljete ili postavljate klevetnički, uvredljiv,
      pornografski, kao ni opscen ili preteći materijal, ili materijal za koji mi
      smatramo da predstavlja uvredu, izaziva neprijatnost, nelagodnost,
      uznemirenost ili anksioznost za bilo koje lice ili sadrži virus ili drugi
      kompjuterski program čija je svrha da ošteti ili ometa rad Site, ili svakog
      kompjutera ili uređaja koji se koriste za pristup ili pregledanje bilo
      jednog bilo drugog.
    </p>
    <p>
      Vi ste saglasni da nećete ometati Site ili nekog drugog korisnika u
      korišćenju Site, uključujući, bez ograničenja, i to da prouzrokujete prekid
      ili oštećenje Site, smanjenje njene efikasnosti ili bilo kakvo drugo
      oštećenje. Vi ste saglasni da nećete zaobilaziti mere koje možemo koristiti
      kako bismo sprečili ili ograničili pristup Site i s njom povezanim podacima.
      Mi zadržavamo pravo, po sopstvenom nahođenju, da u svakom trenutku
      ograničimo ili okončamo vaš pristup ili korišćenje Site bez navođenja
      razloga, prethodne najave ili bilo kakve odgovornosti sa naše strane.
    </p>
    <p>
      4. Tačnost Aplikacije Mi nismo odgovorni za tačnost, potpunost ili
      ažuriranost sadržaja koji se nalaze u Site ili koje ona generiše. Stoga ne
      treba da računate da je bilo koja takva informacija tačna, ažurna ili
      potpuna. Na sadržaje od kojih se Site sastoji ili ih generiše se oslanjate
      na sopstvenu odgovornost. Mi zadržavamo pravo da u svakom trenutku
      modifikujemo Site i njen sadržaj, ali nemamo obavezu da to učinimo.
    </p>
    <p>
      5. Zadržavanje prava intelektualne svojine Vi ne stičete nikakvo pravo,
      vlasništvo ili udeo na Site ili bilo koji sadržaj koji se u Site nalazi ili
      koji ona generiše po osnovu pristupa ili korišćenja Site. Kompanija, njeni
      davaoci licence ili provajderi sadržaja, zadržavaju sva prava, vlasništvo i
      udele u Site, bilo koji sadržaj koji se u Site nalazi ili koji ona generiše,
      uključujući i prava intelektualne svojine. Zabranjena je reprodukcija slika
      sadržanih u Site.
    </p>
    <p>
      6. Žigovi i autorsko pravo Žigovi, logoi i uslužne oznake prikazane na Site
      predstavljaju registrovane i neregistrovane žigove Kompanije, njenih davaoca
      licence i provajdera sadržaja, ili trećih lica. Svi ovi žigovi, logoi i
      uslužne oznake su vlasništvo njihovih odgovarajućih vlasnika. Ništa se na
      Site ne može tumačiti kao da dodeljuje, implicitno, estopelom ili na drugi
      način, dozvolu ili pravo korišćenja bilo kog žiga, logoa ili uslužne oznake
      prikazane na aplikaciji bez prethodnog pisanog odobrenja vlasnika. Mi
      izričito zadržavamo sva prava na Site, sadržajima od kojih se ona sastoji
      ili koje generiše, a koja nisu izričito dodeljena.
    </p>
    <p>
      7. Ograničenje odgovornosti Mi isključujemo, u najvećoj meri koju
      dozvoljavaju važeći propisi (osim u slučaju smrti ili lične povrede
      prouzrokovane našim nemarom) odgovornost za bilo kakva potraživanja,
      gubitke, zahteve ili odštetu koja direktno ili indirektno proizilazi iz ili
      je na bilo koji način vezana za Site. Ovo isključenje odgovornosti se odnosi
      na, bez ograničenja, svaki prekid usluge, izgubljenu dobit, gubitak posla
      ili poslovne prilike, gubitak podataka ili svaku drugu posledičnu, slučajnu,
      specijalnu ili punitivnu štetu koja se javlja u vezi sa Site, čak i kada smo
      bili obavešteni o mogućnosti takve štete, bez obzira da li ima osnov u
      ugovoru, deliktu, zakonu ili na drugi način.
    </p>
    <p>
      8. Promene Site Zadržavamo pravo da okončamo ili modifikujemo Site ili da
      promenimo bilo koji sadržaj koji Site sadrži ili generiše, sa ili bez
      prethodne najave, iz bilo kog razloga. Vi ste saglasni da se mi nećemo
      smatrati odgovornim za bilo koji prekid ili promenu Site ili njenog
      sadržaja.
    </p>
    <p>
      9. Komunikacije Naša Politika privatnosti objašnjava na koji način možemo
      koristiti ili obelodaniti vaše lične podatke.
    </p>
    <p>
      10. Linkovi Site može sadržati linkove ka vebsajtovima koje mi ne
      kontrolišemo. Ovi linkovi su obezbeđeni kao pogodnost za vas. Mi ne
      garantujemo za tačnost podataka sadržanih na ovim vebsajtovima. Mi nismo
      odgovorni za sadržaj ovih vebsajtova.
    </p>
    <p>
      11. Izdvojivost pojedinih odredbi ugovora Ako se za bilo koji deo ovih
      Uslova utvrdi da su protivzakoniti, ništavni ili da ih sud odgovarajuće
      nadležnosti ne može primeniti, onda će takav deo biti izdvojen i izbrisan u
      meri u kojoj je protivzakonit, nevažeći ili neprimenljiv, dok će preostali
      delovi ovih Uslova ostati u potpunosti na snazi i nastaviće da budu
      obavezujući i primenljivi u maksimalnoj meri koju dozvoljava zakon.
    </p>
    <p>
      12. Prenos prava Mi imamo pravo da prenesemo bilo koje naše pravo ili
      obavezu određenu ovim Uslovima na: bilo koju kćerku kompaniju ili povezano
      lice Kompanije; bilo koji subjekat koji stekne svu ili suštinski svu imovinu
      Kompanije, ili bilo koji subjekat koji stekne celokupan ili suštinski ceo
      interes Kompanije u Site ili u za nju vezanoj imovini. Vaša prava i
      povlastice utvrđene ovim Uslovima su personalne prirode i ne mogu biti
      preneti na drugog.
    </p>
    <p>
      13. Merodavno pravo Ovi Uslovi su izričito regulisani i tumače se u skladu
      sa zakonima Engleske i Velsa čiji sudovi imaju isključivu nadležnost u
      svakom sporu. Mi imamo pravo da po sopstvenom nahođenju započnemo i vodimo
      postupak pred nekim drugim sudom.
    </p>
  </div>
  `,
  COMPETITION_RULES: '',
  TERMS_OF_SALE: '',
  LEGAL_NOTICE: '',
};
