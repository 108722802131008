import React, { useEffect } from 'react';
import {
  Heading,
  Type,
  ContentPageLayout,
  ContentRowsLayout,
} from '@nbcuniversal-paint/nbcu-react-components';
import renderHTML from 'html-react-parser';
import Page from '../../components/Page';
import strings from '../../translations';

const { TITLE } = strings.PAGES.TERMS_OF_SALE;
const { TERMS_OF_SALE } = strings;

const Sale = () => {
  useEffect(() => {
    // Check if the page has been reloaded
    if (!sessionStorage.getItem('reloaded')) {
      // Set 'reloaded' flag to true in sessionStorage
      sessionStorage.setItem('reloaded', 'true');
      window.location.reload();
    } else {
      // Remove 'reloaded' flag from sessionStorage
      sessionStorage.removeItem('reloaded');
    }

    const script = document.createElement('script');
    script.src = process.env.REACT_APP_CDN_SRC
      ? `${process.env.REACT_APP_CDN_SRC}?t=${new Date().getTime()}`
      : '';
    script.type = 'text/javascript';
    script.dataset.domainScript = process.env.REACT_APP_COOKIE_ID || '';

    // Insert script to page
    document.head.appendChild(script);

    // Cleanup - remove script from page on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs once on mount and not on updates

  return (
    <Page title={TITLE}>
      <ContentPageLayout>
        <ContentRowsLayout>
          <div>
            <Heading tag="h1" className="h1">
              {TITLE}
            </Heading>
          </div>
          <Type align="left">{renderHTML(TERMS_OF_SALE)}</Type>
        </ContentRowsLayout>
      </ContentPageLayout>
    </Page>
  );
};

export default Sale;
