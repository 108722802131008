import strings from '../../translations';

const { DAYS } = strings.DATE;

/**
 * Return todays date formatted as ISO date.
 *
 * @param {string} date - Date as dd-mm-yyyy.
 * @param {string} delimiter - Date items delimiter.
 * @param {string} time - Hours and Minutes.
 * @returns {string} Formatted date.
 */
export const getIsoDate = (date, delimiter = '-', time = '00:00') => {
  const d = String(date).split(delimiter);
  const t = time.split(':');
  return new Date(d[2], d[1] - 1, d[0], t[0], t[1]);
};

/**
 * Return reformatted as DD-MM-YYYY.
 *
 * @param {string} isoDate - ISO Formatted Date.
 * @returns {string} Simple Formatted date.
 */
export const formatDate = isoDate => {
  const date = new Date(isoDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

/**
 * Pad.
 *
 * @param {number} n
 */
function pad(n) {
  return n < 10 ? `0${n}` : n;
}

/**
 * Return reformatted as DD-MM-YYYY.
 *
 * @param {string} isoDate - ISO Formatted Date.
 * @returns {string} Simple Formatted date.
 */
export const urlFormatDate = isoDate => {
  const date = new Date(isoDate);
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
};

/**
 * GetWeekday name.
 *
 * @param {*} date
 */
export const getWeekday = date => {
  const dayNum = date.getDay();
  return DAYS[dayNum];
};

export const getDisplayDate = date => {
  const dayDate = pad(date.getDate());
  const month = date.getMonth() + 1;
  return `${dayDate}/${month}`;
};

/**
 * FormattedDateString.
 *
 * @param {string} date
 */
export const formattedDateString = date => {
  const dayDate = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear();
  return `${dayDate}-${month}-${year}`;
};

/**
 * Date value plus X days.
 *
 * @param {number} numberToAdd
 * @param {string} isoDate
 */
export const datePlus = (numberToAdd, isoDate) => {
  const date = new Date(isoDate.setDate(isoDate.getDate() + numberToAdd));
  const formattedDate = formattedDateString(date);
  const day = getWeekday(date);
  const displayDate = getDisplayDate(date);

  return {
    day,
    displayDate,
    formattedDate,
  };
};

/**
 * Date value minus X days.
 *
 * @param {number} numberToSubtract
 * @param {string} isoDate
 */
export const dateMinus = (numberToSubtract, isoDate) => {
  const date = new Date(isoDate.setDate(isoDate.getDate() - numberToSubtract));
  const formattedDate = formattedDateString(date);
  const day = getWeekday(date);
  const displayDate = getDisplayDate(date);

  return {
    day,
    displayDate,
    formattedDate,
  };
};

/**
 * ISODateString.
 *
 * @param {string} d
 */
export const ISODateString = d => {
  return `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}T`;
};

/**
 * CalcLocalTime for city.
 *
 * @param {*} offset
 */
export const calcLocalTime = offset => {
  // eslint-disable-next-line no-undef
  const map = new Map([
    [-11, 'Pacific/Niue'],
    [-10, 'Pacific/Tahiti'],
    [-9, 'Pacific/Gambier'],
    [-8, 'Pacific/Pitcairn'],
    [-7, 'America/Vancouver'],
    [-6, 'America/Denver'],
    [-5, 'America/Rio_Branco'],
    [-4, 'America/Manaus'],
    [-3, 'America/Cayenne'],
    [-2, 'Atlantic/South_Georgia'],
    [-1, 'Atlantic/Azores'],
    [0, 'GMT'],
    [1, 'Europe/Brussels'],
    [2, 'Europe/Helsinki'],
    [3, 'Asia/Riyadh'],
    [4, 'Asia/Dubai'],
    [5, 'Asia/Tashkent'],
    [6, 'Asia/Urumqi'],
    [7, 'Asia/Bangkok'],
    [8, 'Asia/Singapore'],
    [9, 'Asia/Chita'],
    [10, 'Pacific/Chuuk'],
    [11, 'Pacific/Pohnpei'],
    [12, 'Pacific/Wake'],
  ]);

  const date = new Date();
  return date.toLocaleTimeString('en-US', { timeZone: map.get(offset) });
};

/**
 * Adds time to a date. Modelled after MySQL DATE_ADD function.
 * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
 * Https://stackoverflow.com/a/1214753/18511.
 *
 * @param {*} date -  - Date to start with.
 * @param {*} interval -  - One of: year, quarter, month, week, day, hour, minute, second.
 * @param {*} units -  - Number of units of the given interval to add.
 */
export function dateAdd(date, interval, units) {
  let ret = new Date(date); // Don't change original date
  const checkRollover = () => {
    if (ret.getDate() !== date.getDate()) ret.setDate(0);
  };
  switch (interval.toLowerCase()) {
    case 'year':
      ret.setFullYear(ret.getFullYear() + units);
      checkRollover();
      break;
    case 'quarter':
      ret.setMonth(ret.getMonth() + 3 * units);
      checkRollover();
      break;
    case 'month':
      ret.setMonth(ret.getMonth() + units);
      checkRollover();
      break;
    case 'week':
      ret.setDate(ret.getDate() + 7 * units);
      break;
    case 'day':
      ret.setDate(ret.getDate() + units);
      break;
    case 'hour':
      ret.setTime(ret.getTime() + units * 3600000);
      break;
    case 'minute':
      ret.setTime(ret.getTime() + units * 60000);
      break;
    case 'second':
      ret.setTime(ret.getTime() + units * 1000);
      break;
    default:
      ret = undefined;
      break;
  }
  return ret;
}
