import React from 'react';
import cx from 'classnames';
import renderHTML from 'html-react-parser';
import { Card, Heading, Type } from '@nbcuniversal-paint/nbcu-react-components';
import styles from './ListItem.module.scss';
import strings from '../../translations';

const { SCHEDULE } = strings;

type Props = {
  className?: string;
  title: string;
  seriesNumber?: string;
  episodeNumber?: string;
  episodeTitle?: string;
  time: string;
  details?: string;
  nowOn: boolean;
};

const ListItem = ({
  className,
  title,
  time,
  episodeTitle,
  seriesNumber,
  episodeNumber,
  details,
  nowOn,
}: Props): JSX.Element => {
  const wrappedTime: string = time.replace(/[0-9:]/g, '<span>$&</span>');

  return (
    <Card size="none" className={cx(className)} align="left">
      <div className={styles.layout}>
        <div className={cx('schedule-time')}>
          <div>{renderHTML(wrappedTime)}</div>
          {nowOn && (
            <div className={cx('list-item-onair', styles.onair)}>
              {SCHEDULE.ON_AIR}
            </div>
          )}
        </div>
        <Card.Content className={styles.content}>
          <Heading tag="h3" className={cx('h4', styles.cardtitle)}>
            {title}
          </Heading>
          {(episodeTitle || seriesNumber || episodeTitle) && (
            <Type
              align="left"
              as="p"
              className={cx('list-item-meta', styles.meta)}
            >
              {episodeTitle && `${episodeTitle}, `}
              {seriesNumber && `${SCHEDULE.SEASON} ${seriesNumber}, `}
              {episodeNumber && `${SCHEDULE.EPISODE} ${episodeNumber}`}
            </Type>
          )}
          {details && (
            <Type
              align="left"
              className={cx('list-item-details', styles.details)}
              as="p"
            >
              {details}
            </Type>
          )}
        </Card.Content>
      </div>
    </Card>
  );
};

ListItem.defaultProps = {
  nowOn: false,
};

export default ListItem;
