import React from 'react';
import DocumentTitle from 'react-document-title';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import Header from '../Elements/Header';
import Footer from '../Elements/Footer';
import PageContext from '../../pages/PageContext';
import strings from '../../translations';
import styles from './Page.module.scss';

const { SITENAME } = strings.GLOBAL;

type Props = {
  children: React.ReactNode;
  title: string;
};

interface LocationState {
  prev: string;
}

const Page = ({ children, title }: Props): React.ReactElement => {
  const theme = process.env.REACT_APP_THEME;
  const epgName = process.env.REACT_APP_EPGNAME;

  const location = useLocation();
  const state = location.state as LocationState;
  const { pathname }: { pathname: string } = location;

  const cx = classNames([styles.page], {
    page: true,
    'page--prev': state && state.prev,
  });

  const context = {
    path: pathname,
  };

  return (
    <DocumentTitle title={`${SITENAME}`}>
      <PageContext.Provider value={context}>
        {epgName !== '13emerue' && epgName !== 'syfy' && <Header />}

        <main className="root-content">
          <section id="page" className={cx}>
            {children}
          </section>
        </main>
        <Footer />
      </PageContext.Provider>
    </DocumentTitle>
  );
};

export default Page;
